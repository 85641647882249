/* GENERAL */
:root {
    --dark-primary: #40514e;
    --dark-800: #092a35;
    --text-primary: #4e5180;
    --gray-primary: #d3d2d2;
    --gray-light: #faf9f6;
    --light-background: #e7eaf6;
    --white: #fff;
    --button-background: #5d5f8a;
    --button-hover-color: #4c4e72;
    --badge-background: #f16e61;

    /* size variables */
    --breakpoint-xs: 0px;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --container-padding-horizontal: 6.75rem;
    --container-padding-vertical: 5rem;
    --container-max-width: 1440px;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    overflow-x: hidden;
    width: 100%;
}

* {
    font-family: "Poppins", sans-serif;
}

main {
    flex: 1 0 auto;
}

a:hover {
    text-decoration: none;
}

pre {
    white-space: normal;
}

.nav-link.active {
    font-weight: 700;
    color: var(--dark-primary) !important;
}

/* members list */
.relatives-list {
    max-width: 500px;
    width: 100%;
}

.relatives-list-header {
    color: var(--text-primary);
    font-size: 1.5rem;
    font-weight: 600;
}

.relative-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.relatives-list li {
    font-size: 1rem;
}

/* ------------ end members list ------------ */
.confirm-modal .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 50px;
}

.confirm-modal .modal-content {
    background-color: var(--light-background);
    border-radius: 30px;
    padding: 10px;
}

.confirm-modal .modal-body {
    color: var(--text-primary);
}

.confirm-modal .btn {
    border-radius: 20px;
    padding-left: 18px;
    padding-right: 18px;
}

/* GLOBAL STYLES that can be shared between components and screens */
/* ----------------------------------- GLOBAL STYLES begin --------------------------------------- */
.global-content {
    padding: 2rem 2.5rem;
}

.global-banner-text-container {
    padding: 2.5rem 2rem;
    display: flex;
    max-width: min(100%, 700px);
    height: 100%;
    flex-direction: column;
    row-gap: 1rem;
}

.global-banner-title {
    color: var(--text-primary);
    font-size: 2.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin: 0;
}

.global-banner-text {
    color: var(--text-primary);
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin: 0;
}

.global-footer-copyright {
    text-align: left;
    padding-left: 0;
}

.global-video-wrapper {
    width: 100%;
}

.global-image-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.global-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.global-image-contain {
    object-fit: contain;
    background-color: #f4f5f5;
}

.global-grid-container-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
}

/* box */
.global-box-line-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.global-box-line {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
}

.global-box-line .global-box,
.global-box-line .global-image-container {
    flex: 50%;
}

.global-background-light {
    background-color: var(--light-background);
    padding: 2rem;
}

.global-background-gray {
    background-color: var(--gray-light);
    padding: 2rem;
}

.services-button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 2.5rem 0;
    flex-direction: column;
    row-gap: 2.5rem;
    align-items: center;
}

.global-privacy-header {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 1.5rem 0;
}

@media screen and (max-width: 1200px) {
    .service-table-container {
        width: 80% !important;
    }
}

@media screen and (max-width: 768px) {
    .service-table-container {
        width: 100% !important;
    }

    .global-grid-container-2 {
        grid-template-columns: 1fr;
    }

    .global-box-line {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }

    .global-box-line:nth-of-type(odd) {
        flex-direction: column-reverse;
    }

    .global-banner-text-container {
        padding: 2rem 0rem;
    }

    .global-banner-title {
        font-size: 2rem;
    }

    .global-privacy-header {
        font-size: 1.5rem;
    }
}

/* GLOBAL STYLES for small screen */
@media screen and (max-width: 768px) {
    .global-content {
        padding: 1rem;
    }

    .global-links-container {
        flex-wrap: wrap !important;
        justify-content: center !important;
    }

    .global-footer-copyright {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }
}

/* end of global style for small screen */

/* ----------------------------------- global styles end -------------------------------------- */

.form-container {
    background: white;
    margin-top: 1rem;
    /*min-height: 98vh;*/
}

.form-row>.col {
    padding-right: 0;
    padding-left: 0;
}

.sticky-div {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    bottom: 0;
    /*display: flex;*/
    /*justify-content: right;*/
}

.blog-wrapper {
    padding: 0;
    margin: 0;
    min-height: 64px;
}

.featured-article-card:hover {
    color: var(--dark-primary);
    text-decoration: none;
}

.featured-article-card {
    color: var(--dark-primary);
    text-decoration: none;
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
    align-items: center;
}

.featured-article-card-div {
    color: var(--dark-primary);
    text-decoration: none;
    width: calc(100% - 64px);
    max-width: 1230px;
    margin: 0 auto;
    align-items: center;
}

.featured-article-card.row {
    flex-direction: row;
    padding: 0 15px;
}

.featured-article-card.column {
    flex-direction: column;
}

.featured-article {
    min-height: 320px;
    margin-bottom: 24px;
}

.featured-article-card-image {
    height: auto;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.featured-article-card-image:hover {
    -webkit-filter: brightness(60%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.featured-article-card-image img, .featured-article-card-image video, .featured-article-card-image iframe {
    width: 100%;
    max-width: 1000px;
    /*max-height: 563px;*/
}

.article-view {
    max-width: 600px;
    margin: 0 auto;
}

.article-title {
    font-size: 2.25rem;
}

.article-byline {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    /*padding: 0 2rem;*/
}

.article-byline .article-byline-info {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.article-byline p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.25;
}

.article-author {
    font-weight: 600;
    text-decoration: none;
}

.article-date {
    font-weight: 300;
}

.article-author-image {
    margin-right: 16px;
    height: 64px;
    width: 64px;
    background-size: contain;
    background-position: center;
}

.article-header .article-feature-image {
    padding: 0;
    margin: auto;
    width: 100%;
    max-width: 1000px;
    background-size: cover;
    background-position: top center;
    height: auto;
}

.article-header .article-feature-image canvas, .article-header .article-feature-image img, .article-header .article-feature-image video, .article-header .article-feature-image iframe {
    width: 100%;
    max-width: 1000px;
    /*max-height: 563px;*/
}

.tags .tags-container {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
}

.tags .tags-container .button {
    background: white;
    color: #686868;
    font-weight: 400;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 16px;
    margin: 0 16px 16px 0;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.article-category {
    font-weight: 700;
    color: #686868;
    font-size: 0.75rem;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0.5rem;
    min-height: 23px;
}

.featured-article-card-body {
    padding: 2rem;
}

.featured-article-card-body>h3 {
    margin-bottom: 1rem;
    font-size: 2.25rem;
}

.featured-article-card-description {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 20px;
    margin-bottom: 32px;
    min-height: 48px;
}

.featured-article a:any-link {
    color: inherit;
    text-decoration: none;
}

.featured-article-card-date {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    color: #686868;
    font-weight: 700;
}

.article-card {
    border: solid 1px #e8e8e8;
    border-radius: 4px;
}

.article-card-image {
    background-size: cover;
    background-position: center;
    /*border-bottom: 1px solid #E8E8E8;*/
    border-radius: 3px 3px 0 0;
    display: block;
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.article-card-image:hover {
    -webkit-filter: brightness(60%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.article-card-image img, .article-card-image video, .article-card-image iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
}

.article-card-body {
    padding: 2rem;
}

.article-card-body>h3 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    min-height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.article-card.column {
    /*flex-direction: column;*/
    margin: 0;
    height: 100%;
}

.blog-items {
    width: calc(100% - 64px);
    margin: 0 auto;
    max-width: 1230px;
}

.article-card:hover {
    color: var(--dark-primary);
    text-decoration: none;
}

.article-card {
    color: var(--dark-primary);
    text-decoration: none;
    max-width: 1230px;
    margin: 0 auto;
}

.blog-items a:any-link {
    color: inherit;
    text-decoration: none;
}

.article-content {
    margin-top: 64px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.article-topics {
    margin-top: 64px;
    margin-bottom: 64px;
    margin: 64px auto 0 auto;
    border-top: 1px solid #e8e8e8;
    max-width: 800px;
}

.palvelut-bottom-pic {
    background-color: #e8ebf6;
}

.palvelut-bottom-pic-image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 256px;
    width: 256px;
    margin: 0 auto;
}

.palvelut-bottom-pic-text {
    margin: 0 auto;
    text-align: center;
    color: #4e5180;
    font-family: "Poppins", sans-serif;
    /*font-weight: bold;*/
    font-size: 1.5rem;
    padding-bottom: 16px;
    max-width: 80%;
}

.sticky-btn {
    background-color: #4e5180 !important;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    border: none;
    margin: 0;
    width: 100%;
}

.hint1 {
    position: absolute;
    left: 100%;
}

.subscribe-fab-div {
    position: absolute;
    right: 16px;
    bottom: 25%;
}

.subscribe-fab {
    border-radius: 50%;
    height: 72px;
    width: 72px;
}

.subscribe-fab>i {
    background-color: #fff;
    background-image: none;
    font-size: 2rem;
}

.subscribe-fab>.badge {
    position: absolute;
    left: 45%;
    top: 15%;
    background: #ff0000;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0;
}

.subscribe-fab-xs {
    display: inline-block;
    position: absolute;
    right: 8px;
}

.subscribe-fab-xs>.badge {
    background: #ff0000;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0;
}

.copyright-mobile {
    display: none;
}

.footer {
    background-color: var(--light-background);
}

/* to be moved to the component */
.services-info-title {
    font-weight: 600;
}

/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
%%%%%%%%%%%%%%%%%%%%%%%%%%%%% SUURIN 793 -> %%%%%%%%%%%%%%%%%%%%%
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

@media only screen and (min-width: 1440px) {
    .logo {
        padding-right: 5rem;
    }
}

@media only screen and (min-width: 635px) {

    /* Navbar */
    .navbar-nav,
    .mr-auto {
        flex: 1;
        margin: auto !important;
        display: flex;
        justify-content: space-between;
        /*padding-right: 4.5rem;*/
    }

    .navbar-dark .navbar-nav .nav-link {
        color: rgb(78, 55, 55) !important;
    }

    .logo {
        color: #4e5180 !important;
        font-size: 1.3em;
        font-weight: bold;
        font-family: "Poppins", sans-serif;
    }

    /*Images */
    .img-fluid.container,
    .card-img-top {
        max-width: 100%;
        height: auto;
        margin: 0 !important;
        padding: 0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        position: relative;
    }

    /* Video player */
    .video-section {
        margin: 0rem 0rem 1.3rem;
    }

    /*--------------------HOMEPAGE 1224PX ---> ---------------------------*/
    /* texts on pic */
    .girlpic-text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 2.6rem;
        font-weight: 600;
        padding-top: 1.7%;
        text-align: center;
    }

    .girlpic-link {
        position: absolute;
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 2.5rem;
        font-weight: 900;
        text-align: center;
        bottom: 4%;
        width: 100%;
    }

    .text-container {
        padding: 2.7rem 4.5rem 3rem 6.3rem;
        max-width: min(1536px, 100%);
    }

    .header-under-pic {
        display: none;
    }

    /* icons */
    .users-ikoni {
        float: left;
        margin-right: 1.5rem;
        margin-top: 0.6rem;
        font-size: 3.2rem;
        color: #4e5180;
    }

    .tablet-ikoni {
        float: left;
        margin-right: 1.9rem;
        margin-top: 0.8rem;
        margin-left: 0.6rem;
        font-size: 3.2rem;
        color: #4e5180;
    }

    .icon {
        color: #4e5180;
        margin: 0 5% 0 5%;
        font-size: 1.6rem;
        float: left;
    }

    .icon:hover {
        color: #494d8f;
    }

    /* header texts */
    .header-div {
        margin: 1.5rem auto 1rem auto;
        text-align: left;
        width: 89%;
        color: rgb(23, 40, 49);
        color: var(--dark-primary);
    }

    .header-text {
        color: rgb(36, 59, 71);
        color: var(--dark-primary);
        font-size: 1.3em;
        font-weight: 300;
        font-family: "Poppins", sans-serif;
        letter-spacing: 0.03em;
        line-height: 2;
    }

    .digihappy-senioritabletti {
        font-weight: 600;
        /* color: #4e5180; */
    }

    .first-text {
        padding-bottom: 1.5rem;
    }

    /* text with checks */
    .text-div {
        margin-bottom: 1rem;
        margin-left: 1rem;
    }

    .text-with-checks {
        padding-top: 2.5rem;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        letter-spacing: 0.1em;
        line-height: 1;
    }

    .text {
        color: rgb(44, 72, 87);
        color: var(--dark-primary);
        font-size: 1.4em;
    }

    .icons-texts {
        margin-left: 1.1rem;
    }

    /* image grid */
    .image-rows {
        padding: 0 1.75rem;
    }

    .between-image-rows {
        height: 0.8rem;
        background: white;
    }

    .manfamily,
    .it-woman {
        padding-right: 0.8rem;
    }

    .woman2,
    .doctor1 {
        padding-left: 0.8rem;
    }

    /* texts under images */
    .image-text {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 500;
        color: rgb(90, 92, 100);
        color: var(--dark-primary);
        margin-top: 0.9rem;
        margin-bottom: 0.2rem;
    }

    .image-link.card-text {
        font-family: "Poppins", sans-serif;
        font-size: 1rem !important;
        padding-bottom: 0.75rem;
    }

    .lue-lisää-linkki {
        font-family: "Poppins", sans-serif;
        color: #4e5180 !important;
        font-size: 1.2rem;
    }

    .lue-lisää-linkki:hover {
        color: #35385d !important;
        text-decoration: none;
    }

    /* bottom pic texts */
    .bottompic-text {
        display: none;
    }

    .bottompic-link {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 2.6rem;
        font-weight: 900;
        text-align: center;
        padding-top: 2.5%;
    }

    .bottom-link {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
    }

    /*----------------------SERVICES PAGE 1224PX ---> --------------------------------- */
    /*text on image*/
    .services-text-on-image {
        color: #4e5180;
        padding-top: 1%;
        padding-left: 32px;
        font-family: "Poppins", sans-serif;
        font-size: 2.6rem;
        font-weight: 600;
        max-width: 50%;
    }

    .services-text-on-image-second {
        font-weight: 600;
        padding-left: 32px;
        font-size: 1.5rem;
        padding-top: 0;
        max-width: 50%;
    }

    /*.services-text-on-image:hover {*/
    /*    color: rgb(33, 37, 44) !important;*/
    /*}*/

    .services-container {
        width: 64%;
    }

    .services-container.services-container-80 {
        width: 80%;
    }

    .services-header {
        color: #4e5180;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
        padding-top: 1.7rem;
        margin-bottom: 3rem;
        text-align: center;
    }

    .services-info {
        margin: auto;
    }

    .services-info-title {
        font-size: 1.6rem;
        color: var(--dark-primary);
        padding-bottom: 0.4rem;
    }

    .services-info-text {
        font-size: 1.4rem;
        color: var(--dark-primary);
        margin-bottom: 0.1rem;
    }

    .box-row {
        border: solid 1px #e6e8f1;
    }

    .info-col,
    .icon-col {
        width: auto;
        min-height: 400px;
    }

    .info-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    li {
        padding-bottom: 0.4rem;
    }

    .icon-col {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e5e8f1;
    }

    .icon-box {
        font-size: 6rem;
        color: #4e5180;
    }

    .ikoni {
        display: flex;
        justify-content: center;
        padding-bottom: 2.5rem;
        font-size: 3rem;
    }

    .options {
        color: #4e5180;
        font-size: 1.4rem;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
    }

    .what-is-best {
        font-weight: 500;
        font-size: 1.5rem;
        color: var(--text-primary);
        padding-top: 1.5rem;
        margin-bottom: 2.2rem !important;
        font-family: "Poppins", sans-serif;
        text-align: center;
        width: 100%;
    }

    /* accordion table button */
    .btn.btn-link.accordion-table-button {
        background: #4e5180 !important;
        padding: 3rem;
        color: #fff !important;
        text-decoration: none;
    }

    .accordion-button-card {
        background: #a6b1e1 !important;
    }

    .accordion-button-header,
    .accordion-button-text {
        text-decoration: none;
    }

    .accordion-button-header {
        font-size: 1.36rem;
        text-align: left;
        /*letter-spacing: 0.21rem;*/
        /*padding-bottom: 1rem;*/
        font-family: "Poppins", sans-serif;
        margin: 0;
    }

    .accordion-button-text {
        font-size: 0.85rem;
        text-align: left;
        /*padding-bottom: 1rem;*/
        font-family: "Poppins", sans-serif;
    }

    .vertaile {
        font-size: 1.6rem;
        padding-bottom: 0.5rem;
        color: #fff;
        font-family: "Poppins", sans-serif;
    }

    .arrow-icon {
        text-align: center;
        font-size: 2.2rem;
        color: #fff;
    }

    .palvelu-tulossa {
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif;
    }

    .dots {
        border-style: none;
        border-top: dotted #a6b1e1;
        border-width: 5px;
        margin-top: 5.5rem;
        width: 5%;
    }

    /*----------------------------TABLE 1224PX ---> --------------------------------*/
    table {
        width: 100%;
        border-collapse: collapse;
    }

    /* Zebra striping */
    tr:nth-of-type(odd) {
        background: #eee;
    }

    th {
        background: #4e5180;
        color: white;
        font-weight: bold;
    }

    td,
    th {
        padding: 6px;
        border: 1px solid #ccc;
        text-align: left;
    }

    .img-fluid.container {
        width: 100vw !important;
        height: 70vh;
        background-size: cover !important;
        background-position: top center !important;
        margin: 0;
    }

    /*----------------------SUBSCRIBE PAGE 1224PX ---> ----------------------------------*/
    /* text on pic */
    .subscribe-text-on-pic {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 2.6rem;
        font-weight: bold;
        padding-left: 70%;
        padding-top: 11.5%;
    }

    .subscribe-text-on-pic:hover {
        color: #35385d;
        text-decoration: none;
    }

    /* under pic */
    .subscribe-header {
        color: #4b4747;
        font-weight: 600;
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        margin-left: 1.5rem;
        font-family: "Poppins", sans-serif;
    }

    .product-row {
        padding-top: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .products-col {
        padding: 1rem;
    }

    .next-to-price-col {
        padding: 1rem;
    }

    .price-col {
        background: rgb(243, 241, 241);
        padding: 1rem;
    }

    .product-title {
        color: #4b4747;
        font-size: 1.7rem;
        text-transform: uppercase;
        font-family: "Poppins", sans-serif;
    }

    .product.body {
        padding: 0;
    }

    .product {
        font-size: 1.3rem;
        letter-spacing: 0.03;
        line-height: 2;
        font-family: "Poppins", sans-serif;
        color: var(--dark-primary);
    }

    .order-conditions {
        color: #4e5180;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
    }

    .price {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
    }

    .add-to-shoppingcart,
    .add-to-shoppingcart-disabled {
        color: #4e5180;
        font-weight: 600;
        margin-top: 0%;
        text-align: center;
        margin-bottom: 2rem;
        font-family: "Poppins", sans-serif;
    }

    .add-to-shoppingcart-disabled {
        color: grey;
    }

    .buy-button {
        border: 0;
        background: rgb(243, 241, 241);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        margin-top: 16%;
        margin: auto;
        margin-bottom: 2.2rem;
    }

    .fa-shopping-cart {
        background-image: repeating-linear-gradient(#8184a7, rgb(211, 208, 208) 10%, rgb(35, 35, 77) 20%);
        /* color: transparent; */
        -webkit-background-clip: text;
        background-clip: text;
    }

    .fa-trash-alt {
        background-image: repeating-linear-gradient(#8184a7, rgb(211, 208, 208) 10%, rgb(35, 35, 77) 20%);
        color: transparent;
        -webkit-background-clip: text;
    }

    .three-month-price {
        font-size: 1.5rem;
        font-weight: 600;
        margin-left: 0.5rem;
        color: #000;
        text-align: center;
        background: #e8ebf6;
        padding: 8px;
        border-radius: 24px;
    }

    /*------------------------CONTACT PAGE 1224PX ---> ----------------------------------------*/
    /* texts on connect pic */
    .ota-yhteyttä {
        display: flex;
        font-size: 2.6em;
        padding-top: 0.2%;
        text-align: left;
        margin-bottom: 0.1rem;
        color: #4e5180;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
    }

    .apu {
        font-size: 1.5em;
        text-align: left;
        color: #4e5180;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
    }

    .contact-page {
        height: 100vh;
    }

    .contact-texts-column {
        margin-top: 2rem;
    }

    /* contact form */
    .form-container,
    .contact-container {
        margin: 0;
        padding: 0;
    }

    .contact-form {
        margin-top: 0.8rem;
        padding: 1rem 2rem;
        font-size: 1rem;
        font-family: "Poppins", sans-serif;
    }

    .contact-form-column {
        margin-top: 0rem;
        padding: 1rem 0;
        border-bottom-right-radius: 10px;
    }

    input#contact-name,
    input#contact-email {
        border: solid 1px rgb(214, 212, 212) !important;
    }

    .textarea {
        resize: none;
        background: transparent;
        border: solid 2px rgb(211, 208, 208) !important;
    }

    /*---------------------FOR COMPANIES PAGE 1224PX ---> --------------------*/
    /* texts on for-companies-img */
    .forcompanies-text-on-image {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
    }

    .aboutus-text-on-image {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-weight: 600;
        padding-top: 2rem;
        font-size: 2.6rem;
    }

    .faq-text-on-image {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-weight: 600;
        padding-top: 26vh;
        font-size: 2.6rem;
        padding-left: 8vw;
    }

    .faq-text-on-image-second {
        font-size: 1.5rem;
        padding-top: 0.5rem;
    }

    .forcompanies-first {
        font-weight: 600;
        padding-top: 1%;
        padding-left: 54.5%;
        font-size: 2.6rem;
        line-height: 3rem;
    }

    .forcompanies-second {
        font-weight: 600;
        padding-left: 54.5%;
        font-size: 1.5rem;
    }

    .for-companies-header {
        display: none;
    }

    .why-digihappy {
        font-family: "Poppins", sans-serif;
        font-size: 2.1rem;
        text-align: center;
        margin-top: 4rem;
        padding-bottom: 1.7rem;
        color: #4b4747;
    }

    /* texts under pic
same settings as on the homepage */
    .texts-for-companies {
        font-family: "Poppins", sans-serif;
        font-size: 1.5rem;
        line-height: 2;
        letter-spacing: 0.5;
        margin: 3rem auto 3rem auto;
        width: 80%;
        color: var(--dark-primary);
        padding-left: 30px;
        padding-right: 30px;
    }

    .text-for-companies-second {
        padding-bottom: 1.5rem;
    }

    /* text-area under images in the grid */
    .card-body {
        height: auto;
        padding: 0rem;
        font-size: 1rem;
        /*max-width: 48vw;*/
        border: none;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
    }

    .contact-link {
        display: flex;
        align-items: center;
        padding-bottom: 3rem;
    }

    .ota-yhteyttä-linkki {
        margin: auto;
        padding-top: 2rem;
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .ota-yhteyttä-linkki:hover {
        font-weight: bold;
        color: #35385d;
        text-decoration: none;
    }

    /*----------------------LOGIN PAGE 1224PX ---> ------------------------------*/
    .signin {
        color: #092a35;
        color: #4e5180;
        padding-top: 1rem;
        padding-bottom: 1.2rem;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    .login-form {
        margin-top: 6.5rem;
        margin-bottom: 6.5rem;
        background-color: rgb(253, 249, 249);
        padding: 1rem 1.5rem;
        border-radius: 4px;
        font-family: "Poppins", sans-serif;
        font-size: 1.1rem;
    }

    /* submit-buttons*/
    .tallenna {
        background-color: #4e5180 !important;
        color: #fff !important;
        margin-bottom: 1.9rem;
        margin-top: 0.5rem;
        font-family: "Poppins", sans-serif;
        border: none;
    }

    .forgot-password {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
    }

    /*---------------------FOOTER 1224PX ---> -------------------------------*/
    .footer-row,
    .footer,
    .privacy-policy,
    .text-center.col-md-6.col-12.links,
    .copyright {
        /*padding-bottom: 0.5rem;*/
        /*margin: 0;*/
        background: var(--light-background);
    }

    .privacy-policy,
    .copyright,
    .page-links {
        color: rgb(51, 49, 49);
        font-size: 0.9rem;
        /*padding-bottom: 0;*/
        /*padding-top: 0.6rem;*/
        /*margin-bottom: 0;*/
        font-family: "Poppins", sans-serif;
    }

    .copyright {
        margin: auto;
    }

    .copyright-link {
        padding-right: 1.4rem;
    }

    .privacy-policy-link {
        padding-left: 1.4rem;
    }

    .copyright-mobile.nav-link {
        /*display: none;*/
        background-color: var(--light-background);
    }

    /*--------------------REGISTER PAGE 1224 ---------------*/
    /*steps */
    .circles-row {
        display: flex;
        justify-content: center;
        margin-bottom: 4rem;
    }

    .icon-in-circle {
        font-size: 2rem;
        padding: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-in-circle.active {
        /* color: #4e5180; */
        color: #fff;
    }

    .steps-name {
        color: #4e5180;
        font-size: 0.64rem;
        line-height: 0;
    }

    /* circles and texts under them*/
    li.step {
        width: 5em;
        height: 5em;
        text-align: center;
        line-height: 1.4em;
        border-radius: 50%;
        border: rgb(206, 199, 199) solid 1px;
        /*margin: 0 4.5% 0 4.5%;*/
        margin: 0;
        display: inline-block;
        color: grey;
        position: relative;
    }

    /* lines */
    /*li::before {*/
    /*  content: '';*/
    /*  position: absolute;*/
    /*  top: 2.5em;*/
    /*  left: -8.99em;*/
    /*  right: 8.99em;*/
    /*  width: 8.99em;*/
    /*  height: 0.1em;*/
    /*  border: dashed 1px rgb(219, 216, 216);*/
    /*  z-index: -1;*/
    /*}*/
    /*li:first-child::before {*/
    /*  display: none;*/
    /*}*/
    .step.active {
        background: #f0f1f3;
        background: #4e5180;
        border: solid 1px #4e5180;
    }

    .step.active::before {
        background: #a0a0a5;
        border: solid 2px #a0a0a5;
        height: 0.2em;
    }

    .step-line {
        width: 9%;
        margin-top: 40px;
        padding: 0;
        background: #a0a0a5;
        border: none;
        height: 4px;
    }

    .step-line::marker {
        content: none;
    }

    .alert-success {
        background: #fff !important;
        font-weight: 600;
        color: #4e5180;
        margin-top: 2rem;
        padding: 0;
    }

    .register {
        padding: 0;
    }

    .register-header {
        color: #4e5180;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
    }

    .register-form {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-radius: 2px;
        padding: 1.5rem;
        background: #e9ebed;
        font-family: "Poppins", sans-serif;
    }

    .password-length {
        color: rgb(77, 74, 74);
    }

    .conditions {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
    }

    .register-button {
        margin-top: 1.3rem;
        font-family: "Poppins", sans-serif;
        border: none;
    }

    /*--------------------SHIPPING PAGE 1224 ---------------*/
    .shipping-header,
    .shipping-form {
        font-family: "Poppins", sans-serif;
    }

    .shipping-header {
        color: #4e5180;
        margin-bottom: 1rem;
        margin-top: 3rem;
        font-weight: 600;
    }

    /*--------------------SHOPPINGCART 1224 ---------------*/
    .shopping-header,
    .shoppingcart-container {
        font-family: "Poppins", sans-serif;
    }

    .shoppingcart-header {
        color: #4e5180;
        margin-bottom: 1.4rem;
        margin-top: 4rem;
        margin-left: 0.9rem;
        font-weight: 600;
    }

    .shoppingcart-services-col {
        padding-left: 5.9rem;
    }

    .service-name {
        color: #4b4747 !important;
        font-size: 1.3rem;
        margin-top: 0.4rem;
    }

    .shoppingcart-service-price {
        font-size: 1.3rem;
        padding-left: 2.6rem;
        margin-top: 0.37rem;
    }

    .price-sum-col {
        /*margin-top: 9.13rem;*/
        padding-right: 4.5rem;
        padding-left: 0;
        margin-left: 0;
    }

    .shoppingcart-back-button,
    .btn-block {
        font-size: 1.3rem;
    }

    .shoppingcart-back-button {
        margin-left: 0.9rem;
        background: #fff !important;
        color: #4e5180;
        border: 1px solid #4e5180;
        width: 16.6rem;
    }

    .shoppingcart-back-button:hover {
        background: #4e5180 !important;
        color: #fff;
        border: none;
    }

    .shoppingcart-service-trash-button {
        background: #fff;
        color: grey;
        border: none;
        margin-top: 0.2rem;
    }

    /*--------------------PAYMENTMETHOD 1224 ---------------*/
    .paymentmethod-header {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin: 2rem 0;
    }

    .paymentmethod-col {
        font-family: "Poppins", sans-serif;
    }

    .radio-button {
        margin: 0.6rem 0;
    }

    .form-check-input {
        position: absolute;
        margin-top: 0.4rem;
        margin-left: -1.25rem;
    }

    .paymentmethod-back-button {
        min-width: 6.4rem;
        background: #fff !important;
        color: #4e5180 !important;
        border: 1px solid #4e5180;
    }

    /*--------------------PLACE ORDER PAGE 1224 ---------------*/
    .placeorder-header {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        letter-spacing: 0.1rem;
        margin: 1rem 0 0 0;
    }

    .placeorder-headers {
        color: #2c3635;
    }

    .order-info-col {
        font-family: "Poppins", sans-serif;
        padding-left: 3.2rem;
        margin: 0 auto;
    }

    .order-summary-col {
        /*margin-top: 11.1rem;*/
        font-family: "Poppins", sans-serif;
    }

    .placeorder-texts {
        font-size: 1.2rem;
        letter-spacing: 0.03rem;
    }

    .selected-item {
        /*margin-left: -1rem;*/
        padding-bottom: 0;
        padding-left: 0;
    }

    .selected-product {
        color: #4e5180;
        font-weight: 700;
    }

    .selected-product-price {
        text-align: right;
    }

    .placeorder-back-button {
        background: #fff !important;
        color: #4e5180;
        border: 1px solid #4e5180;
        font-size: 1.3rem;
        margin-top: 8px;
    }

    .placeorder-back-button:hover {
        background: #4e5180 !important;
        color: #fff;
        border: none;
    }

    /*///////////////////////////////////////////////////////////
------------------------USER PROFILE PAGE 1224PX ---> ------------------
///////////////////////////////////////////////////////////*/
    /* Subnavbar */
    .subnavbar.userpage-nav {
        background: #f8f9fa;
        border-top: rgb(219, 215, 215) solid 1px;
        border-bottom: rgb(219, 215, 215) solid 1px;
        font-family: "Poppins", sans-serif;
        width: 100%;
        padding: 0 16px;
    }

    .video-link,
    .health-link,
    .account-link {
        float: right;
        font-size: 1.3rem;
        color: #4e5180;
        padding-left: 0.2rem;
        padding-right: 2.5rem;
    }

    .video-link:hover span span,
    .health-link:hover span span,
    .account-link:hover span span {
        font-weight: bold;
        color: #35385d;
    }

    /*.video-link,*/
    /*.health-link {*/
    /*  color: grey;*/
    /*}*/
    /*.video-link {*/
    /*  color: #fff;*/
    /*}*/
    /* sidebar */
    .sidebar-outer {
        background: #fff !important;
        margin-top: 20% !important;
        margin-bottom: 0 !important;
        margin-left: 0.97rem !important;
        padding-right: 3rem;
        padding-left: 3rem;
        min-height: 98vh;
    }

    /* inner */
    .home-image-card,
    .for-companies-image-card {
        border: none;
    }

    /*outer*/
    .home-column,
    .for-companies-column {
        padding: 0rem;
    }

    /* Tabs */
    .nav-tabs .nav-link {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 1.4rem;
        border: none;
    }

    .nav-tabs .nav-link:hover {
        color: #35385d;
    }

    .nav-tabs .nav-link.active {
        color: #4e5180;
        font-weight: 600;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
    }

    /* Tabs */
    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link>a {
        color: #fff;
        background: #4e5180 !important;
        text-align: center;
    }

    .nav-pills .nav-link {
        color: #131212;
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif;
        position: relative;
    }

    .tilaustiedot {
        padding-left: 1%;
        color: #4e5180;
        font-size: 1.7rem;
        font-family: "Poppins", sans-serif;
    }

    .variation-col,
    .order-col {
        padding: 0;
    }

    .group {
        padding-right: 0rem;
        padding-left: 11rem;
    }

    .welcome-greeting {
        padding: 1.5rem 0;
        font-size: 1.4rem;
        font-family: "Poppins", sans-serif;
    }

    .instruction,
    .introduction {
        font-family: "Poppins", sans-serif;
        padding: 0;
        padding-right: 3rem;
        justify-content: space-evenly;
    }

    .instructions {
        font-size: 1.2rem;
        color: var(--dark-primary);
        letter-spacing: 0.02rem;
        line-height: 2;
    }

    .introduction-header {
        margin-top: 1.2rem;
        margin-bottom: 1.15rem;
        color: #4e5180;
        font-weight: 600;
    }

    .customer-info {
        color: black;
    }

    .added-members {
        padding: 1.6rem 0 1rem 0.3rem;
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-size: 1.6rem;
    }

    .members-list {
        /* padding: 0 1rem 2rem 1rem; */
        padding-left: 1rem;
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        /* display: inline-block; */
    }

    .membership {
        list-style-type: none;
    }

    .role {
        margin-bottom: 0;
    }

    .relative-members {
        list-style-type: none;
    }

    .order-header {
        padding: 1.5rem 0;
        margin-left: 1.8rem;
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-size: 1.6rem;
    }

    /*-----------------------USER FORM 1224PX ---> -------------------------------*/
    /* message text in box */
    .alert-success {
        background-color: var(--light-background);
        border: none;
        color: rgb(39, 38, 38);
        font-size: 1.4rem;
        font-family: "Poppins", sans-serif;
        min-width: 32vw;
    }

    .user-form {
        padding-top: 1.5rem;
        font-size: 1.1rem;
        font-family: "Poppins", sans-serif;
        min-width: 32vw;
    }

    .user-form-button {
        margin-bottom: 2rem;
        border: none;
    }

    .is-senior-button,
    .what-is-this,
    .for-example,
    .form-check-label {
        background-color: #fff !important;
        color: rgb(80, 78, 78);
    }

    .is-senior-button {
        margin-top: 0;
    }

    .click-checkbox {
        color: red;
    }

    .checkbox-later {
        margin-top: 0.4rem;
    }

    .accordion-button {
        background: white;
        border: none;
        text-align: left;
        padding-top: 0;
        padding-left: 0;
        text-decoration: none;
    }

    .btn-link:hover {
        text-decoration: none;
    }

    /*---------------------------SENIOR FORM 1224 ----------------------*/
    .accordion-btn {
        border: solid 1px rgb(177, 173, 173);
        color: rgb(24, 23, 23);
        min-width: 100%;
    }

    .accordiong-btn:hover {
        text-decoration: none;
    }

    .invisible {
        visibility: hidden;
    }

    /* --------------------------MEMBER FORM ----------------------*/
    .member-form-header,
    .user-form-header,
    .senior-detail-header {
        padding-top: 1rem;
        font-family: "Poppins", sans-serif;
        color: #4e5180;
    }

    .member-button {
        width: 13rem;
    }

    .send-member-email-button {
        margin-left: 2.2rem;
    }
}

/*////////////////////////////////////////////////////////////
////////////////////////////TABLET //////////////////////////////////
///////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

@media only screen and (min-width: 635px) and (max-device-width: 792px) {
    body {
        overflow-x: hidden !important;
        margin: 0 auto;
    }

    .logo {
        color: #4e5180;
        font-weight: 600;
        font-size: 1.5rem;
        font-family: "Poppins", sans-serif;
    }

    .img-fluid.container,
    .card-img,
    .card-img-top {
        max-width: 100vw !important;
        height: auto !important;
        background-size: contain !important;
        background-position: top center !important;
    }

    /* --------------------HOMEPAGE-----------*/
    .img-fluid.girlpic {
        height: 58vh !important;
    }

    /* texts on girlpic */
    .girlpic-text,
    .girlpic-link {
        display: none;
    }

    .header-under-pic {
        color: #4e5180;
        font-size: 1.43rem;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-top: 1.3rem;
        text-align: center;
    }

    /* texts with icons under the girl pic*/
    .text-container {
        padding: 1.1rem 1.5rem 1.3rem 3rem;
    }

    .header-text {
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.6;
    }

    .digihappy-senioritabletti {
        font-weight: 600;
    }

    .first-text {
        padding: 1.2rem 1.5rem 1.3rem 5.1rem;
    }

    .second-text {
        padding: 0 1.5rem 0rem 5.1rem;
    }

    .users-ikoni {
        display: block !important;
        padding-top: 1.4rem;
        float: left;
        margin-right: 2rem;
        font-size: 2.5rem;
        color: #4e5180;
    }

    .tablet-ikoni {
        display: block !important;
        float: left;
        margin-top: 0.4rem;
        margin-left: 0.5rem;
        margin-right: 2.3rem;
        margin-bottom: 0.333333rem;
        font-size: 2.5rem;
        color: #4e5180;
    }

    /* texts with check icons */
    /* set space between headers and check-texts*/
    .text-with-checks {
        padding-top: 0.9rem;
        padding-bottom: 1rem;
    }

    .text-div {
        margin: 0.84rem;
    }

    .text {
        color: rgb(90, 92, 100);
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        display: flex;
        letter-spacing: 0.03em;
        line-height: 1.6;
        padding-bottom: 0.35rem;
    }

    /* check icon*/
    .icon {
        color: #4e5180;
        float: left;
        margin-top: 0.3rem;
        margin-right: 2.88rem;
        font-size: 1.3rem;
    }

    /* image grid homepage and for companies page*/
    .card-body {
        height: auto;
        padding: 0rem;
        font-size: 1rem;
        border: none;
        padding-top: 0.2rem;
        padding-bottom: 0.8rem;
    }

    .home-image-card {
        border: none;
    }

    .card-img-top {
        border-top-right-radius: 0%;
        border-top-left-radius: 0%;
    }

    .manfamily,
    .it-woman {
        padding-right: 0rem;
    }

    .woman2,
    .doctor1 {
        padding-left: 0rem;
    }

    .box-row {
        border: none;
    }

    .image-rows {
        padding: 0 0.91rem;
    }

    .between-image-rows {
        height: 0rem;
    }

    /* texts under images */
    .image-text {
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.1;
        color: rgb(90, 92, 100);
        margin-top: 1.2rem;
        margin-bottom: 0.4rem;
        /*width: 100vw !important;*/
    }

    .image-link.card-text {
        font-family: "Poppins", sans-serif;
        padding-bottom: 0.5rem;
        /*width: 100vw !important;*/
    }

    .lue-lisää-linkki {
        color: #4e5180 !important;
        font-size: 0.9rem;
        text-align: center;
    }

    .lue-lisää-linkki:hover {
        color: #35385d !important;
        text-decoration: none;
    }

    /* bottom pic homepage */
    .img-fluid.bottompic {
        height: 70vh !important;
    }

    .bottompic-text {
        display: none;
    }

    .bottompic-link {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-size: 1.45rem;
        font-weight: 600;
        text-align: center;
        padding-top: 4%;
        display: none;
    }

    a.bottom-link {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
    }

    /*------------------SERVICES PAGE---------------------*/
    .img-fluid.servicespic {
        height: 60vh !important;
    }

    .services-text-on-image {
        display: none;
    }

    .services-container {
        width: 96%;
        margin: auto;
    }

    .services-header {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 0.02em;
        margin-left: 0.7rem !important;
        margin-bottom: 1.6rem;
        padding-top: 0.7rem;
    }

    .services-info-title {
        font-size: 1.2rem;
        color: var(--dark-primary);
        margin-left: 0rem;
        margin-bottom: 0.7rem;
    }

    .info-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 2.6rem;
        padding-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 0.4rem;
        height: auto;
        font-family: "Poppins", sans-serif;
        letter-spacing: 0.02em;
        line-height: 1.6;
        border: none;
    }

    .options {
        color: #4e5180;
        text-align: left;
        font-family: "Poppins", sans-serif;
        font-size: 1.1rem;
        font-weight: 600;
        letter-spacing: 0.03em;
        line-height: 1.5;
    }

    li,
    .services-info-text {
        color: var(--dark-primary);
        font-size: 1.05rem;
        padding-left: 1.3rem;
        padding-bottom: 0.4rem;
    }

    /* distance between bullet point and text */
    li {
        padding-left: 0.1rem;
    }

    /* no icons displayed */
    .icon-col {
        height: 0;
    }

    .fa-tablet-alt,
    .fa-tablet,
    .fa-video,
    .fa-users,
    .fa-heartbeat,
    .fa-phone-square {
        display: none;
    }

    .dots {
        display: none;
    }

    .info-col {
        width: auto;
        height: 320px;
    }

    .info-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 0rem;
        height: auto;
    }

    .icon-col {
        display: none;
    }

    /* icon inside the box */
    .icon-box {
        font-size: 4rem;
        color: #4e5180;
    }

    .ikoni {
        display: flex;
        justify-content: center;
        padding-bottom: 2.5rem;
        font-size: 2rem;
    }

    .dots {
        display: none;
    }

    .what-is-best {
        font-family: "Poppins", sans-serif;
        font-size: 1.5rem;
        font-weight: 500;
        color: var(--text-primary);
        padding-top: 0rem;
        padding-bottom: 1.5rem !important;
        margin-bottom: 0rem !important;
    }

    .accordion-table-button {
        background-color: #4e5180 !important;
    }

    .accordion-button-header {
        /*color: #fff;*/
        font-family: "Poppins", sans-serif;
        font-size: 1.36rem;
        text-align: left;
        /*letter-spacing: 0.1rem;*/
        /*padding: 2rem 0 0.5rem 0;*/
    }

    .accordion-button-text {
        /*color: #fff;*/
        font-family: "Poppins", sans-serif;
        font-size: 0.85rem;
        text-align: left;
        /*padding-bottom: 0.5rem;*/
    }

    .vertaile {
        font-family: "Poppins", sans-serif;
        font-size: 1.1rem;
        padding-bottom: 0;
        margin-bottom: 0;
        color: #fff;
    }

    .arrow-icon {
        color: #fff !important;
        content: "\f358";
        font-size: 1.2rem;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
    }

    .fa-check-circle {
        color: #4e5180 !important;
    }

    /* ------------------SUBSCRIBE PAGE -------------------------*/
    .img-fluid.subscribepic {
        height: 60vh !important;
    }

    /* text on pic */
    .subscribe-text-on-pic {
        display: none;
    }

    .subscribe-text-on-pic:hover {
        color: #35385d;
        text-decoration: none;
    }

    .subscribe-header {
        color: rgb(139, 138, 138);
        color: rgb(75, 71, 71);
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        margin-right: 0;
        margin-bottom: 1.7rem;
        margin-top: 1.9rem;
        margin-left: 0.3rem;
    }

    .p-5 {
        padding: 0rem !important;
    }

    .products-container {
        width: 100vw;
    }

    .product-row {
        padding-top: 0;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        /* background: rgb(243, 241, 241); */
    }

    .sticky-div {
        bottom: 48px;
    }

    .products-col {
        padding: 0.5rem;
    }

    .next-to-price-col {
        padding: 0.15rem;
    }

    .price-col {
        background: rgb(243, 241, 241);
    }

    .product-body {
        padding: 0;
    }

    .product-title {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        padding-right: 6rem;
        text-transform: uppercase;
        color: rgb(75, 71, 71);
    }

    .product {
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        font-size: 0.9rem;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.6;
        margin-right: 2rem;
    }

    .price {
        color: #4e5180;
        font-weight: 600;
    }

    a.order-conditions {
        color: #4e5180;
        font-weight: 500;
    }

    .add-to-shoppingcart {
        color: #4e5180;
        padding-top: 8%;
        padding-left: 0rem;
        font-family: "Poppins", sans-serif;
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 2rem;
        text-align: center;
    }

    .three-month-price {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        margin-left: 0rem;
        text-align: center;
        color: rgb(75, 71, 71);
        padding: 8px;
        background: #e8ebf6;
        border-radius: 24px;
        padding-bottom: 9.5%;
    }

    .buy-card.card-body {
        background: rgb(243, 241, 241);
        padding: 0;
    }

    .buy-button {
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(243, 241, 241);
        font-size: 2.5rem;
        margin: auto;
        margin-bottom: 2rem;
        padding-left: 0rem;
        text-align: center;
    }

    .fa-shopping-cart {
        background-image: repeating-linear-gradient(#8184a7, rgb(211, 208, 208) 10%, rgb(35, 35, 77) 20%);
        /* color: transparent; */
        -webkit-background-clip: text;
        background-clip: text;
    }

    .fa-trash-alt {
        background-image: repeating-linear-gradient(#8184a7, rgb(211, 208, 208) 10%, rgb(35, 35, 77) 20%);
        color: transparent;
        -webkit-background-clip: text;
    }

    /*-----------------------CONTACT PAGE ---------------------*/
    .img-fluid.contact-pic {
        height: 60vh !important;
    }

    .contact-texts-column {
        margin-top: 0;
        position: relative;
    }

    /* texts on pic */
    .ota-yhteyttä {
        color: #4e5180;
        padding-top: 17%;
        padding-left: 80%;
        font-family: "Poppins", sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
    }

    .apu {
        display: none;
    }

    .contact-form {
        /*width: 60vw;*/
        /*margin: 38vh auto 3rem auto;*/
        font-family: "Poppins", sans-serif;
    }

    .label-name {
        margin-top: 2.2rem;
    }

    .contact-button {
        margin-bottom: 4rem;
        background-color: #4e5180 !important;
        border: none;
        color: #fff !important;
    }

    /* -------------------FOR COMPANIES PAGE  ---------------*/
    .img-fluid.forcompaniespic {
        height: 60vh !important;
    }

    /* texts on image */
    .forcompanies-first {
        color: #4e5180;
        font-weight: 600;
        padding-top: 1%;
        padding-left: 47%;
        font-size: 1.3rem;
        line-height: 1.6rem;
        font-family: "Poppins", sans-serif;
    }

    .forcompanies-second {
        color: #4e5180;
        font-weight: 600;
        padding-left: 47%;
        font-size: 0.88rem;
        font-family: "Poppins", sans-serif;
    }

    .for-companies-header {
        display: none;
    }

    .text-for-companies-third,
    .text-for-companies-first {
        padding-bottom: 1rem;
    }

    .why-digihappy {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        margin-left: 9.88%;
        padding-bottom: 1.1rem;
    }

    /* texts under the image */
    .for-companies-text-under-img {
        padding-bottom: 0.7rem;
    }

    .texts-for-companies {
        color: var(--dark-primary);
        width: 80%;
        margin: 2.5rem auto;
        font-family: "Poppins", sans-serif;
        font-size: 1.05rem;
        letter-spacing: 0.02em;
        line-height: 1.6;
    }

    /* texts under images */
    .image-text {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 500;
        color: rgb(90, 92, 100);
        color: var(--dark-primary);
        margin-top: 0.9rem;
        margin-bottom: 0.2rem;
        /*width: 100vw;*/
    }

    .image-link.card-text {
        font-family: "Poppins", sans-serif;
        font-size: 1rem !important;
        padding-bottom: 0.75rem;
        /*width: 100vw;*/
    }

    .contact-link {
        display: flex;
        align-items: center;
        padding-bottom: 3rem;
    }

    .ota-yhteyttä-linkki {
        margin: auto;
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-weight: 600;
    }

    .ota-yhteyttä-linkki:hover {
        font-weight: bold;
        color: #35385d;
        text-decoration: none;
    }

    /*------------------------LOGIN PAGE  -------------------------*/
    .img-fluid.login-woman {
        /* height: 70vh !important; */
    }

    .login-form {
        margin: 5rem auto;
        background: #fff;
        padding: 1.2rem 1.5rem;
        font-family: "Poppins", sans-serif;
    }

    .signin {
        color: #4e5180;
        padding-top: 0;
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 0.7rem;
        text-align: left;
        font-family: "Poppins", sans-serif;
    }

    .forgot-password {
        color: #4e5180;
        padding-left: 0.26rem;
        font-size: 1rem;
        padding-top: 0 !important;
        padding-bottom: 2rem;
        font-family: "Poppins", sans-serif;
    }

    .login-button {
        margin-top: 0.6rem;
        margin-bottom: 1rem;
        background-color: #4e5180 !important;
        border: none;
        color: #fff !important;
        font-family: "Poppins", sans-serif;
    }

    /*----------------------FOOTER  ----------------------*/
    .page-links {
        /*display: none;*/
    }

    .footer-row,
    .privacy-policy,
    .text-center.col-md-6.col-12.links,
    .copyright {
        /*padding-top: 1rem;*/
        /*margin: 0;*/
        /*background: #fff;*/
        /*position: fixed;*/
        /*bottom: 0;*/
        /*left: -1px;*/
        /*right: 2rem;*/
        width: 100%;
        height: 3rem;
    }

    .privacy-policy,
    .copyright {
        font-size: 0.9rem;
        /*padding-bottom: 0.5rem;*/
        /*margin-top: 0.7rem;*/
        background: #fff;
        width: 100%;
    }

    .copyright-mobile {
        display: block;
    }

    .copyright-mobile.nav-link {
        color: rgb(122, 120, 120);
        margin-top: 0;
        padding-bottom: 0;
    }

    /*--------------------REGISTER PAGE  --------------------*/
    .register {
        padding: 0;
    }

    .register-header {
        padding-top: 1.9rem;
        padding-bottom: 1rem;
        text-align: center;
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 1.6rem;
        font-weight: 600;
    }

    .register-form {
        border-radius: 7px;
        box-shadow: 0 2px 5px #ccc;
        padding: 1.5rem;
        background: #e9ebed;
        border-radius: 0 !important;
        font-family: "Poppins", sans-serif;
        margin: 0 auto 2rem auto;
    }

    .password-length {
        color: rgb(77, 74, 74);
    }

    .conditions {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
    }

    .register-button {
        margin-top: 1.3rem;
        background-color: #4e5180 !important;
        margin-bottom: 2rem;
        border: none;
        font-family: "Poppins", sans-serif;
    }

    /*///////////////////////////////////////////////////////////
  ------------------USER PROFILE PAGE  ----------
  ///////////////////////////////////////////////////////////*/
    .user-page {
        overflow-x: hidden;
    }

    .welcome-greeting {
        color: #35413e;
        margin: 0 1rem;
    }

    .userpage-nav {
        background: var(--light-background);
    }

    .video-link,
    .health-link,
    .account-link {
        color: #4e5180;
        padding-left: 0;
        font-family: "Poppins", sans-serif;
        display: inline;
    }

    .video-link:hover span span,
    .health-link:hover span span,
    .account-link:hover span span {
        font-weight: bold;
        color: #35385d;
    }

    .video-link,
    .health-link {
        color: grey;
    }

    .video-link {
        color: #fff;
    }

    .tilaustiedot {
        color: #4e5180;
        margin: 0.6rem;
        font-family: "Poppins", sans-serif;
    }

    /* sidebar */
    .sidebar-outer {
        background: #fff !important;
        margin-top: 22% !important;
        margin-bottom: 0 !important;
        margin-left: 0.97rem !important;
        padding-right: 0rem;
        padding-left: 0.5rem;
        min-height: 68vh;
        min-width: 30vw;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link>a {
        color: #fff;
        background: #4e5180 !important;
        text-align: center;
        font-family: "Poppins", sans-serif;
        margin: 0 0.79rem 0 0;
        max-width: 96%;
    }

    .nav-pills .nav-link {
        text-align: center;
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
    }

    .added-members {
        padding: 1rem 0 0 1.5rem;
        color: #4e5180;
    }

    .members-list {
        /* padding: 0 1rem 2rem 1rem; */
        padding-left: 1.5rem;
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        /* display: inline-block; */
    }

    .group {
        padding-bottom: 3rem;
    }

    .membership {
        list-style-type: none;
    }

    .role {
        margin-bottom: 0;
    }

    .alert-success {
        background-color: var(--light-background);
        border: none;
        color: rgb(37, 36, 36);
        min-width: 40vw;
        margin-top: 1rem;
        font-family: "Poppins", sans-serif;
    }

    .user-form {
        min-width: 40vw;
        padding-left: 0;
        padding-top: 1rem;
        margin: 0 auto;
        font-family: "Poppins", sans-serif;
    }

    .user-form-button,
    .member-button,
    .senior-button {
        margin-bottom: 4rem;
        background-color: #4e5180 !important;
        border: none;
        color: #fff !important;
    }

    .what-is-this {
        color: #212529;
    }

    .checkbox-later {
        color: rgb(36, 35, 35);
    }

    .is-senior-button,
    .what-is-this,
    .for-example,
    .form-check-label {
        background-color: #fff !important;
        color: rgb(80, 78, 78);
    }

    /* senior form */
    .accordion-btn {
        border: solid 1px rgb(211, 206, 206);
        color: rgb(24, 23, 23);
        min-width: 100%;
        margin-bottom: 1rem;
    }

    .accordiong-btn:hover {
        text-decoration: none;
    }

    .nursering-home-input {
        margin-bottom: 1rem;
        /* margin-top: -5rem; */
    }

    .lives-at-home-input {
        /* margin-top: -5.5rem; */
    }

    .invisible {
        visibility: hidden;
    }

    .send-member-email-col {
        display: flex;
        justify-content: center;
    }

    .send-member-email-button {
        margin-left: 0;
    }

    .member-button,
    .send-member-email-button {
        width: 100%;
    }

    .palvelut-bottom-pic {
        margin-bottom: 48px;
    }

    .palvelut-bottom-pic-image {
        width: 128px;
        height: 128px;
    }

    .palvelut-bottom-pic-text {
        font-size: 1.2rem;
    }
}

/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
%%%%%%%%%%%%%%%%%      M O B I L E  --> 633 %%%%%%%%%%%%%%%%%
&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/

@media screen and (max-width: 634px) {
    body {
        overflow-x: hidden !important;
        margin: 0 auto;
    }

    .logo {
        color: #4e5180;
        font-weight: 600;
        font-size: 1.5rem;
        font-family: "Poppins", sans-serif;
    }

    .img-fluid.container,
    .card-img,
    .card-img-top {
        max-width: 100vw !important;
        height: auto !important;
        background-size: contain !important;
        background-position: top center !important;
    }

    /* --------------------HOMEPAGE-----------*/
    .img-fluid.girlpic {
        height: 58vh !important;
    }

    /* texts on girlpic */
    .girlpic-text,
    .girlpic-link {
        display: none;
    }

    .header-under-pic {
        color: #4e5180;
        font-size: 1.43rem;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-top: 1.3rem;
        text-align: center;
    }

    /* texts with icons under the girl pic*/
    .text-container {
        padding: 1.1rem 1.5rem 1.3rem 3rem;
    }

    .header-text {
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.6;
    }

    .digihappy-senioritabletti {
        font-weight: 600;
    }

    .first-text {
        padding: 1.2rem 1.5rem 1.3rem 5.1rem;
    }

    .second-text {
        padding: 0 1.5rem 0rem 5.1rem;
    }

    .users-ikoni {
        display: block !important;
        padding-top: 1.4rem;
        float: left;
        margin-right: 2rem;
        font-size: 2.5rem;
        color: #4e5180;
    }

    .tablet-ikoni {
        display: block !important;
        float: left;
        margin-top: 0.4rem;
        margin-left: 0.5rem;
        margin-right: 2.3rem;
        margin-bottom: 0.333333rem;
        font-size: 2.5rem;
        color: #4e5180;
    }

    /* texts with check icons */
    /* set space between headers and check-texts*/
    .text-with-checks {
        padding-top: 0.9rem;
        padding-bottom: 1rem;
    }

    .text-div {
        margin: 0.84rem;
    }

    .text {
        color: rgb(90, 92, 100);
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        display: flex;
        letter-spacing: 0.03em;
        line-height: 1.6;
        padding-bottom: 0.35rem;
    }

    /* check icon*/
    .icon {
        color: #4e5180;
        float: left;
        margin-top: 0.3rem;
        margin-right: 2.88rem;
        font-size: 1.3rem;
    }

    /* image grid homepage and for companies page*/
    .card-body {
        height: auto;
        padding: 0rem;
        font-size: 1rem;
        border: none;
        padding-top: 0.2rem;
        padding-bottom: 0.8rem;
    }

    .home-image-card {
        border: none;
    }

    .card-img-top {
        border-top-right-radius: 0%;
        border-top-left-radius: 0%;
    }

    .manfamily,
    .it-woman {
        padding-right: 0rem;
    }

    .woman2,
    .doctor1 {
        padding-left: 0rem;
    }

    .box-row {
        border: none;
    }

    .image-rows {
        padding: 0 0.91rem;
    }

    .between-image-rows {
        height: 0rem;
    }

    /* texts under images */
    .image-text {
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.1;
        color: rgb(90, 92, 100);
        margin-top: 1.2rem;
        margin-bottom: 0.4rem;
        /*width: 100vw !important;*/
    }

    .image-link.card-text {
        font-family: "Poppins", sans-serif;
        padding-bottom: 0.5rem;
        /*width: 100vw !important;*/
    }

    .lue-lisää-linkki {
        color: #4e5180 !important;
        font-size: 0.9rem;
        text-align: center;
    }

    .lue-lisää-linkki:hover {
        color: #35385d !important;
        text-decoration: none;
    }

    /* bottom pic homepage */
    .img-fluid.bottompic {
        height: 70vh !important;
    }

    .bottompic-text {
        display: none;
    }

    .bottompic-link {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-size: 1.45rem;
        font-weight: 600;
        text-align: center;
        padding-top: 4%;
        display: none;
    }

    a.bottom-link {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
    }

    /*------------------SERVICES PAGE---------------------*/
    .img-fluid.servicespic {
        height: 60vh !important;
    }

    .services-text-on-image {
        display: none;
    }

    .services-container {
        width: 96%;
        margin: auto;
    }

    .services-header {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 0.02em;
        margin-left: 0.7rem !important;
        margin-bottom: 1.6rem;
        padding-top: 0.7rem;
    }

    .services-info-title {
        font-size: 1.2rem;
        color: var(--dark-primary);
        margin-left: 0rem;
        margin-bottom: 0.7rem;
    }

    .info-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 2.6rem;
        padding-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 0.4rem;
        height: auto;
        font-family: "Poppins", sans-serif;
        letter-spacing: 0.02em;
        line-height: 1.6;
        border: none;
    }

    .options {
        color: #4e5180;
        text-align: left;
        font-family: "Poppins", sans-serif;
        font-size: 1.1rem;
        font-weight: 600;
        letter-spacing: 0.03em;
        line-height: 1.5;
    }

    li,
    .services-info-text {
        color: var(--dark-primary);
        font-size: 1.05rem;
        padding-left: 1.3rem;
        padding-bottom: 0.4rem;
    }

    /* distance between bullet point and text */
    li {
        padding-left: 0.1rem;
    }

    /* no icons displayed */
    .icon-col {
        height: 0;
    }

    .fa-tablet-alt,
    .fa-tablet,
    .fa-video,
    .fa-users,
    .fa-heartbeat,
    .fa-phone-square {
        display: none;
    }

    .dots {
        display: none;
    }

    .info-col {
        width: auto;
        height: 320px;
    }

    .info-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 0rem;
        height: auto;
    }

    .icon-col {
        display: none;
    }

    /* icon inside the box */
    .icon-box {
        font-size: 4rem;
        color: #4e5180;
    }

    .ikoni {
        display: flex;
        justify-content: center;
        padding-bottom: 2.5rem;
        font-size: 2rem;
    }

    .dots {
        display: none;
    }

    .what-is-best {
        font-family: "Poppins", sans-serif;
        font-size: 1.5rem;
        font-weight: 500;
        color: var(--text-primary);
        padding-top: 0rem;
        padding-bottom: 1.5rem !important;
        margin-bottom: 0rem !important;
    }

    .accordion-table-button {
        background-color: #4e5180 !important;
    }

    .accordion-button-header {
        /*color: #fff;*/
        font-family: "Poppins", sans-serif;
        font-size: 1.36rem;
        text-align: left;
        /*letter-spacing: 0.1rem;*/
        /*padding: 2rem 0 0.5rem 0;*/
    }

    .accordion-button-text {
        /*color: #fff;*/
        font-family: "Poppins", sans-serif;
        font-size: 0.85rem;
        text-align: left;
        /*padding-bottom: 0.5rem;*/
    }

    .vertaile {
        font-family: "Poppins", sans-serif;
        font-size: 1.1rem;
        padding-bottom: 0;
        margin-bottom: 0;
        color: #fff;
    }

    .arrow-icon {
        color: #fff !important;
        content: "\f358";
        font-size: 1.2rem;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
    }

    .fa-check-circle {
        color: #4e5180 !important;
    }

    /* ------------------SUBSCRIBE PAGE -------------------------*/
    .img-fluid.subscribepic {
        height: 60vh !important;
    }

    /* text on pic */
    .subscribe-text-on-pic {
        display: none;
    }

    .subscribe-text-on-pic:hover {
        color: #35385d;
        text-decoration: none;
    }

    .subscribe-header {
        color: rgb(139, 138, 138);
        color: rgb(75, 71, 71);
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        margin-right: 0;
        margin-bottom: 1.7rem;
        margin-top: 1.9rem;
        margin-left: 0.3rem;
    }

    .p-5 {
        padding: 0rem !important;
    }

    .products-container {
        width: 100vw;
    }

    .product-row {
        padding-top: 0;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        /* background: rgb(243, 241, 241); */
    }

    .sticky-div {
        bottom: 48px;
    }

    .products-col {
        padding: 0.5rem;
    }

    .next-to-price-col {
        padding: 0.15rem;
    }

    .price-col {
        background: rgb(243, 241, 241);
    }

    .product-body {
        padding: 0;
    }

    .product-title {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        padding-right: 6rem;
        text-transform: uppercase;
        color: rgb(75, 71, 71);
    }

    .product {
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        font-size: 0.9rem;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.6;
        margin-right: 2rem;
    }

    .price {
        color: #4e5180;
        font-weight: 600;
    }

    a.order-conditions {
        color: #4e5180;
        font-weight: 500;
    }

    .add-to-shoppingcart {
        color: #4e5180;
        padding-top: 8%;
        padding-left: 0rem;
        font-family: "Poppins", sans-serif;
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 2rem;
        text-align: center;
    }

    .three-month-price {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        margin-left: 0rem;
        text-align: center;
        color: rgb(75, 71, 71);
        padding: 8px;
        background: #e8ebf6;
        border-radius: 24px;
        padding-bottom: 9.5%;
    }

    .buy-card.card-body {
        background: rgb(243, 241, 241);
        padding: 0;
    }

    .buy-button {
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(243, 241, 241);
        font-size: 2.5rem;
        margin: auto;
        margin-bottom: 2rem;
        padding-left: 0rem;
        text-align: center;
    }

    .fa-shopping-cart {
        background-image: repeating-linear-gradient(#8184a7, rgb(211, 208, 208) 10%, rgb(35, 35, 77) 20%);
        /* color: transparent; */
        -webkit-background-clip: text;
        background-clip: text;
    }

    .fa-trash-alt {
        background-image: repeating-linear-gradient(#8184a7, rgb(211, 208, 208) 10%, rgb(35, 35, 77) 20%);
        color: transparent;
        -webkit-background-clip: text;
    }

    /*-----------------------CONTACT PAGE ---------------------*/
    .img-fluid.contact-pic {
        height: 60vh !important;
    }

    .contact-texts-column {
        margin-top: 0;
        position: relative;
    }

    /* texts on pic */
    .ota-yhteyttä {
        color: #4e5180;
        padding-top: 17%;
        padding-left: 80%;
        font-family: "Poppins", sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
    }

    .aboutus-text-on-image {
        color: #4e5180;
        padding-top: 17%;
        padding-left: 80%;
        font-family: "Poppins", sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
    }

    .faq-text-on-image {
        color: #4e5180;
        padding-top: 10vh;
        padding-left: 8vw;
        font-family: "Poppins", sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
    }

    .faq-text-on-image-second {
        font-size: 1rem;
        padding-top: 0.5rem;
    }

    .apu {
        display: none;
    }

    .contact-form {
        /*width: 60vw;*/
        /*margin: 38vh auto 4rem auto;*/
        font-family: "Poppins", sans-serif;
    }

    .label-name {
        margin-top: 2.2rem;
    }

    .contact-button {
        margin-bottom: 4rem;
        background-color: #4e5180 !important;
        border: none;
        color: #fff !important;
    }

    /* -------------------FOR COMPANIES PAGE  ---------------*/
    .img-fluid.forcompaniespic {
        height: 60vh !important;
    }

    /* texts on image */
    .forcompanies-first {
        color: #4e5180;
        font-weight: 600;
        padding-top: 1%;
        padding-left: 47%;
        font-size: 1.3rem;
        line-height: 1.6rem;
        font-family: "Poppins", sans-serif;
    }

    .forcompanies-second {
        color: #4e5180;
        font-weight: 600;
        padding-left: 47%;
        font-size: 0.88rem;
        font-family: "Poppins", sans-serif;
    }

    .for-companies-header {
        display: none;
    }

    .text-for-companies-third,
    .text-for-companies-first {
        padding-bottom: 1rem;
    }

    .why-digihappy {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        margin-left: 9.88%;
        padding-bottom: 1.1rem;
    }

    /* texts under the image */
    .for-companies-text-under-img {
        padding-bottom: 0.7rem;
    }

    .texts-for-companies {
        color: var(--dark-primary);
        width: 80%;
        /*margin: 2.5rem auto;*/
        font-family: "Poppins", sans-serif;
        font-size: 1.05rem;
        letter-spacing: 0.02em;
        line-height: 1.6;
    }

    /* texts under images */
    .image-text {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 500;
        color: rgb(90, 92, 100);
        color: var(--dark-primary);
        margin-top: 0.9rem;
        margin-bottom: 0.2rem;
        /*width: 100vw;*/
    }

    .image-link.card-text {
        font-family: "Poppins", sans-serif;
        font-size: 1rem !important;
        padding-bottom: 0.75rem;
        /*width: 100vw;*/
    }

    .contact-link {
        display: flex;
        align-items: center;
        padding-bottom: 3rem;
    }

    .ota-yhteyttä-linkki {
        margin: auto;
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-weight: 600;
    }

    .ota-yhteyttä-linkki:hover {
        font-weight: bold;
        color: #35385d;
        text-decoration: none;
    }

    /*------------------------LOGIN PAGE  -------------------------*/
    .img-fluid.login-woman {
        /* height: 70vh !important; */
    }

    .login-form {
        margin: 5rem auto;
        background: #fff;
        padding: 1.2rem 1.5rem;
        font-family: "Poppins", sans-serif;
    }

    .signin {
        color: #4e5180;
        padding-top: 0;
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 0.7rem;
        text-align: left;
        font-family: "Poppins", sans-serif;
    }

    .forgot-password {
        color: #4e5180;
        padding-left: 0.26rem;
        font-size: 1rem;
        padding-top: 0 !important;
        padding-bottom: 2rem;
        font-family: "Poppins", sans-serif;
    }

    .login-button {
        margin-top: 0.6rem;
        margin-bottom: 1rem;
        background-color: #4e5180 !important;
        border: none;
        color: #fff !important;
        font-family: "Poppins", sans-serif;
    }

    /*----------------------FOOTER  ----------------------*/
    .page-links {
        /*display: none;*/
    }

    .footer-row,
    .privacy-policy,
    .text-center.col-md-6.col-12.links,
    .copyright {
        /*padding-top: 1rem;*/
        margin: 0;
        background: #fff;
        /*position: fixed;*/
        /*bottom: 0;*/
        /*left: -1px;*/
        /*right: 2rem;*/
        width: 100%;
        height: 3rem;
    }

    .privacy-policy,
    .copyright {
        font-size: 0.9rem;
        /*padding-bottom: 0.5rem;*/
        /*margin-top: 0.7rem;*/
        background: #fff;
        width: 100%;
    }

    .copyright-mobile {
        display: block;
    }

    .copyright-mobile.nav-link {
        color: rgb(122, 120, 120);
        margin-top: 0;
        padding-bottom: 0;
    }

    /*--------------------REGISTER PAGE  --------------------*/
    .register {
        padding: 0;
    }

    .register-header {
        padding-top: 1.9rem;
        padding-bottom: 1rem;
        text-align: center;
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 1.6rem;
        font-weight: 600;
    }

    .register-form {
        border-radius: 7px;
        box-shadow: 0 2px 5px #ccc;
        padding: 1.5rem;
        background: #e9ebed;
        border-radius: 0 !important;
        font-family: "Poppins", sans-serif;
        margin: 0 auto 2rem auto;
    }

    .password-length {
        color: rgb(77, 74, 74);
    }

    .conditions {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
    }

    .register-button {
        margin-top: 1.3rem;
        background-color: #4e5180 !important;
        margin-bottom: 2rem;
        border: none;
        font-family: "Poppins", sans-serif;
    }

    /*///////////////////////////////////////////////////////////
  ------------------USER PROFILE PAGE  ----------
  ///////////////////////////////////////////////////////////*/
    .user-page {
        overflow-x: hidden;
    }

    .welcome-greeting {
        color: #35413e;
        margin: 0 1rem;
    }

    .userpage-nav {
        background: var(--light-background);
    }

    .video-link,
    .health-link,
    .account-link {
        color: #4e5180;
        padding-left: 0;
        font-family: "Poppins", sans-serif;
        display: inline;
    }

    .video-link:hover span span,
    .health-link:hover span span,
    .account-link:hover span span {
        font-weight: bold;
        color: #35385d;
    }

    .video-link,
    .health-link {
        color: grey;
    }

    .video-link {
        color: #fff;
    }

    .tilaustiedot {
        color: #4e5180;
        margin: 0.6rem;
        font-family: "Poppins", sans-serif;
    }

    /* sidebar */
    .sidebar-outer {
        background: #fff !important;
        margin-top: 22% !important;
        margin-bottom: 0 !important;
        margin-left: 0.97rem !important;
        padding-right: 0rem;
        padding-left: 0.5rem;
        min-height: 68vh;
        min-width: 30vw;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link>a {
        color: #fff;
        background: #4e5180 !important;
        text-align: center;
        font-family: "Poppins", sans-serif;
        margin: 0 0.79rem 0 0;
        max-width: 96%;
    }

    .nav-pills .nav-link {
        text-align: center;
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
    }

    .added-members {
        padding: 1rem 0 0 1.5rem;
        color: #4e5180;
    }

    .members-list {
        /* padding: 0 1rem 2rem 1rem; */
        padding-left: 1.5rem;
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        /* display: inline-block; */
    }

    .group {
        padding-bottom: 3rem;
    }

    .membership {
        list-style-type: none;
    }

    .role {
        margin-bottom: 0;
    }

    .alert-success {
        background-color: var(--light-background);
        border: none;
        color: rgb(37, 36, 36);
        min-width: 40vw;
        margin-top: 1rem;
        font-family: "Poppins", sans-serif;
    }

    .user-form {
        min-width: 40vw;
        padding-left: 0;
        padding-top: 1rem;
        margin: 0 auto;
        font-family: "Poppins", sans-serif;
    }

    .user-form-button,
    .member-button,
    .senior-button {
        margin-bottom: 4rem;
        background-color: #4e5180 !important;
        border: none;
        color: #fff !important;
    }

    .what-is-this {
        color: #212529;
    }

    .checkbox-later {
        color: rgb(36, 35, 35);
    }

    .is-senior-button,
    .what-is-this,
    .for-example,
    .form-check-label {
        background-color: #fff !important;
        color: rgb(80, 78, 78);
    }

    /* senior form */
    .accordion-btn {
        border: solid 1px rgb(211, 206, 206);
        color: rgb(24, 23, 23);
        min-width: 100%;
        margin-bottom: 1rem;
    }

    .accordiong-btn:hover {
        text-decoration: none;
    }

    .nursering-home-input {
        margin-bottom: 1rem;
        /* margin-top: -5rem; */
    }

    .lives-at-home-input {
        /* margin-top: -5.5rem; */
    }

    .invisible {
        visibility: hidden;
    }

    .send-member-email-col {
        display: flex;
        justify-content: center;
    }

    .send-member-email-button {
        margin-left: 0;
    }

    .member-button,
    .send-member-email-button {
        width: 100%;
    }

    .palvelut-table-div {
        overflow-x: auto;
    }

    .featured-article-card {
        width: 100%;
    }

    .blog-items {
        width: 100%;
    }

    .article-content {
        padding: 0 2rem;
    }

    .article-topics {
        padding: 0 2rem;
    }

    .article-feature-image {
        /*max-height: 20vh;*/
    }

    .article-category {
        padding: 0 32px;
        text-align: center;
    }

    .article-title {
        padding: 0 32px;
    }

    .article-title.article-view h1 {
        font-size: 1.25rem;
        color: #242424;
        font-weight: 700;
        padding: 0 32px;
    }

    .article-header-container {
        margin-top: 64px;
    }

    .article-byline {
        padding: 0 32px;
    }

    .palvelut-bottom-pic {
        margin-bottom: 48px;
    }

    .palvelut-bottom-pic-image {
        width: 128px;
        height: 128px;
    }

    .palvelut-bottom-pic-text {
        font-size: 1.2rem;
        max-width: 80%;
    }

    .subscribe-fab-div {
        display: none;
    }

    .hint1 {
        position: relative;
        left: 0;
    }

    .step-line {
        display: none;
    }

    .icon-in-circle {
        color: gray;
        margin-right: 8px;
    }

    .icon-in-circle.active {
        color: var(--text-primary);
    }
}

/*&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&&&&&&&&&&&&&&&&  ERIKOISET &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&*/

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    body {
        overflow-x: hidden !important;
        margin: 0 auto;
    }

    .logo {
        color: #4e5180;
        font-weight: 600;
        font-size: 1.5rem;
        font-family: "Poppins", sans-serif;
    }

    .navbar-nav,
    .mr-auto {
        flex: 1;
        margin: auto !important;
        display: flex;
        justify-content: space-between;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: rgb(78, 55, 55) !important;
    }

    .img-fluid.container,
    .card-img,
    .card-img-top {
        max-width: 100vw !important;
        height: auto !important;
        background-size: contain !important;
        background-position: top center !important;
    }

    /* submit-buttons*/
    .tallenna {
        background-color: #4e5180 !important;
        border: none;
        color: #fff !important;
        margin-bottom: 1.9rem;
    }

    /* -------------------HOMEPAGE 320-480 ---------------------------------------*/
    .img-fluid.girlpic {
        height: 38vh !important;
    }

    /* texts on girl pic */
    .girlpic-text,
    .girlpic-link {
        display: none;
    }

    /* texts with icons under the girl pic*/
    .text-container {
        padding: 1.5rem 1.3rem 1rem 1.8rem;
    }

    .header-under-pic {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        text-align: center;
    }

    .header-text {
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.02em;
        line-height: 1.6;
        /* clear: left; */
        padding-top: 0.4rem;
    }

    .first-text {
        padding-bottom: 1.5rem;
    }

    .users-ikoni {
        display: block !important;
        float: center;
        font-size: 2rem;
        color: #4e5180;
        margin-top: 1.5rem;
    }

    .tablet-ikoni {
        display: block !important;
        float: center;
        font-size: 2rem;
        color: #4e5180;
    }

    .digihappy-senioritabletti {
        font-weight: 600;
        color: #4e5180;
    }

    /* texts with check icons */
    .text-with-checks {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        padding-top: 1rem;
    }

    .text-div {
        margin-left: 0rem;
        margin-bottom: 1.2rem;
        margin-top: 0.5rem;
    }

    .text {
        color: rgb(90, 92, 100);
        font-size: 1rem;
        display: flex;
        letter-spacing: 0.03em;
        line-height: 1.6;
        padding-bottom: 0rem;
    }

    /* check icon*/
    .icon {
        float: left;
        margin-top: 0.1rem;
        margin-right: 0.8rem;
        font-size: 1.2rem;
    }

    /* image grid homepage and for companies page*/
    .card-body {
        height: auto;
        padding: 0rem;
        font-size: 1rem;
        border: none;
        padding-top: 0.2rem;
        padding-bottom: 0.8rem;
    }

    .home-image-card {
        border: none;
    }

    .card-img-top {
        border-top-right-radius: 0%;
        border-top-left-radius: 0%;
    }

    .manfamily,
    .it-woman {
        padding-right: 0rem;
    }

    .woman2,
    .doctor1 {
        padding-left: 0rem;
    }

    .box-row {
        border: none;
    }

    .image-rows {
        padding: 0 0.91rem;
    }

    .between-image-rows {
        height: 0rem;
    }

    /* texts under images */
    .image-text {
        font-family: "Poppins", sans-serif;
        font-size: 1.05rem;
        font-weight: 500;
        color: rgb(90, 92, 100);
        margin-top: 0.9rem;
        margin-bottom: 0.2rem;
    }

    .image-link.card-text {
        font-family: "Poppins", sans-serif;
        font-size: 1rem !important;
        padding-bottom: 0.75rem;
    }

    .lue-lisää-linkki {
        color: #4e5180 !important;
        font-size: 0.9rem;
    }

    .lue-lisää-linkki:hover {
        color: #35385d !important;
        text-decoration: none;
    }

    /* bottom pic homepage */
    .img-fluid.bottompic {
        height: 50vh !important;
    }

    .bottompic-text {
        display: none;
    }

    .bottompic-link {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        padding-top: 3%;
    }

    a.bottom-link {
        font-family: "Poppins", sans-serif;
        color: #4e5180;
    }

    /*------------------SERVICES PAGE 320-480 ---------------------*/
    .img-fluid.servicespic {
        height: 38vh !important;
    }

    .services-text-on-image {
        display: none;
    }

    .services-container {
        width: 100%;
        margin: auto;
    }

    .services-header {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 0.02rem;
        margin-left: 0.6rem !important;
        margin-bottom: 0.2rem;
        padding-top: 0.5rem;
    }

    .services-info-title {
        font-size: 1.1rem;
        color: var(--dark-primary);
        margin-left: -1rem;
        margin-bottom: 0.5rem;
    }

    .info-col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 1.3rem 1rem 2.6rem;
        height: auto;
        font-family: "Poppins", sans-serif;
        letter-spacing: 0.02em;
        line-height: 2;
    }

    .options {
        color: #4e5180;
        text-align: left;
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: 2;
    }

    li,
    .services-info-text {
        color: var(--dark-primary);
        font-size: 0.94rem;
        padding-left: 0;
        padding-bottom: 0.1rem;
    }

    /* distance between bullet point and text */
    li {
        padding-left: 0.1rem;
    }

    /* no icons displayed */
    .icon-col {
        height: 0;
    }

    .fa-tablet-alt,
    .fa-tablet,
    .fa-video,
    .fa-users,
    .fa-heartbeat,
    .fa-phone-square {
        display: none;
    }

    .dots {
        display: none;
    }

    .what-is-best {
        font-family: "Montserrat", sans-serif;
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--text-primary);
        padding-top: 0rem;
        padding-bottom: 1.5rem !important;
        margin-bottom: 0rem !important;
    }

    .accordion-table-button {
        background-color: #4e5180 !important;
    }

    .accordion-button-header,
    .accordion-button-text {
        text-decoration: none;
    }

    .accordion-button-header {
        /*color: #fff;*/
        font-family: "Poppins", sans-serif;
        font-size: 1.36rem;
        text-align: left;
        /*letter-spacing: 0.1rem;*/
        /*padding: 2rem 0 0.5rem 0;*/
    }

    .accordion-button-text {
        /*color: #fff;*/
        font-family: "Poppins", sans-serif;
        font-size: 0.85rem;
        text-align: left;
        /*padding-bottom: 0.5rem;*/
    }

    .vertaile {
        font-family: "Poppins", sans-serif;
        font-size: 1.1rem;
        padding-bottom: 0;
        margin-bottom: 0;
        color: #fff;
    }

    .arrow-icon {
        color: #fff !important;
        content: "\f358";
        font-size: 1.2rem;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
    }

    .fa-check-circle {
        color: #4e5180 !important;
    }

    .connect-img {
        height: auto;
    }

    /* ------------------SUBSCRIBE PAGE 320-480 -------------------------*/
    .img-fluid.subscribepic {
        height: 38vh !important;
    }

    .subscribe-text-on-pic {
        display: none;
    }

    .tilaa-palvelu {
        margin-bottom: 0;
        padding-bottom: 0;
        padding-top: 0.5rem;
    }

    .subscribe-header {
        color: rgb(139, 138, 138);
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 500;
        margin-right: 0;
        margin-bottom: 1.4rem;
    }

    .p-5 {
        padding: 0rem !important;
    }

    .product-row {
        background: rgb(243, 241, 241);
        padding: 1rem 0.5rem 1rem 1.1rem;
        margin: 0;
    }

    .product-title {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        text-transform: uppercase;
        color: rgb(75, 71, 71);
    }

    .product {
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        font-size: 0.9rem;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.6;
    }

    .price {
        color: #4e5180;
        font-weight: 600;
    }

    a.order-conditions {
        color: #4e5180;
        font-weight: 500;
    }

    .price-col {
        padding-left: 0;
        padding-right: 0;
    }

    .add-to-shoppingcart {
        color: #4e5180;
        padding-top: 10%;
        font-family: "Poppins", sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0;
    }

    .three-month-price {
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        margin-left: 0rem;
        color: rgb(75, 71, 71);
        padding: 8px;
        background: #e8ebf6;
        border-radius: 24px;
        padding-bottom: 2rem;
    }

    .buy-card.card-body {
        background: rgb(243, 241, 241);
        padding-left: 1rem;
        padding-top: 0;
    }

    .buy-button {
        border: 0;
        background: rgb(243, 241, 241);
        font-size: 2.5rem;
        margin-top: 5%;
        margin-left: auto;
        margin-bottom: 5%;
        padding-left: 0;
    }

    .fa-shopping-cart {
        background-image: repeating-linear-gradient(#8184a7, rgb(211, 208, 208) 10%, rgb(35, 35, 77) 20%);
        /* color: transparent; */
        -webkit-background-clip: text;
        background-clip: text;
    }

    .fa-trash-alt {
        background-image: repeating-linear-gradient(#8184a7, rgb(211, 208, 208) 10%, rgb(35, 35, 77) 20%);
        color: transparent;
        -webkit-background-clip: text;
    }

    .palvelut-bottom-pic {
        margin-bottom: 48px;
    }

    .palvelut-bottom-pic-image {
        width: 128px;
        height: 128px;
    }

    .palvelut-bottom-pic-text {
        font-size: 1.2rem;
        max-width: 80%;
    }

    /*-----------------------CONTACT PAGE 320-480 ---------------------*/
    .img-fluid.contact-pic {
        height: 38vh !important;
    }

    .contact-texts-column {
        margin-top: 0;
    }

    /* texts on pic */
    .ota-yhteyttä {
        color: #4e5180;
        padding-top: 16%;
        padding-left: 74%;
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
    }

    .apu {
        display: none;
    }

    .contact-form {
        /*margin-top: 40%;*/
    }

    .label-name {
        margin-top: 2.2rem;
    }

    .contact-button {
        margin-bottom: 3rem;
        border: none;
    }

    /* --------------------FOR COMPANIES PAGE 320-480 ---------------*/
    .img-fluid.forcompaniespic {
        height: 38vh !important;
    }

    /* texts on image */
    .forcompanies-first,
    .forcompanies-second {
        display: none;
    }

    .for-companies-header {
        color: #4e5180;
        font-weight: 600;
        padding-top: 0.3rem;
        padding-bottom: 1.1rem;
    }

    .text-for-companies-third {
        padding-bottom: 1rem;
    }

    .why-digihappy {
        font-family: "Poppins", sans-serif;
        font-size: 1.1rem;
        margin-left: 7%;
        padding-bottom: 0.7rem;
    }

    /* texts under the image */
    .for-companies-text-under-img {
        padding-bottom: 0.7rem;
    }

    .texts-for-companies {
        color: var(--dark-primary);
        width: 80%;
        margin: 1.5rem auto;
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        letter-spacing: 0.02em;
        line-height: 1.6;
    }

    .contact-link {
        display: flex;
        align-items: center;
        padding-bottom: 3rem;
    }

    .ota-yhteyttä-linkki {
        margin: auto;
        font-family: "Poppins", sans-serif;
        color: #4e5180;
        font-weight: 600;
    }

    .ota-yhteyttä-linkki:hover {
        font-weight: bold;
        color: #35385d;
        text-decoration: none;
    }

    /*------------------------LOGIN PAGE 320-480 -------------------------*/
    .img-fluid.login-woman {
        /* height: 38vh !important; */
    }

    .login-form {
        margin: 5rem auto;
        background: #fff;
        padding: 0.3rem 1.5rem;
    }

    .form-label {
        font-family: "Poppins", sans-serif;
    }

    .signin {
        color: #4e5180;
        padding-top: 0;
        font-size: 1.4rem;
        font-weight: 600;
        padding-bottom: 0.7rem;
        text-align: left;
        font-family: "Poppins", sans-serif;
    }

    .forgot-password {
        color: #4e5180;
        padding-left: 1.31%;
        font-size: 1rem;
        padding-top: 0 !important;
        padding-bottom: 2rem;
        font-family: "Poppins", sans-serif;
    }

    .login-button {
        margin-top: 0.6rem;
        margin-bottom: 1rem;
        font-family: "Poppins", sans-serif;
        border: none;
    }

    /*--------------------FOOTER 320-480 ---------------------------*/
    .page-links {
        /*display: none;*/
    }

    .footer-row,
    .privacy-policy,
    .text-center.col-md-6.col-12.links,
    .copyright {
        /*padding-top: 0.5rem;*/
        /*margin: 0;*/
        /*background: #fff;*/
        /*position: fixed;*/
        /*bottom: 0;*/
        /*left: -1px;*/
        /*right: 2rem;*/
        width: 100%;
    }

    .privacy-policy,
    .copyright {
        font-size: 0.8rem;
        /*padding-bottom: 0.5rem;*/
        /*margin-top: 0.7rem;*/
        /*background: #fff;*/
        width: 100%;
    }

    .copyright-mobile {
        display: block;
    }

    .copyright-mobile.nav-link {
        color: rgb(122, 120, 120);
        margin-top: 0;
        padding-bottom: 0;
    }

    /*-----------------REGISTER PAGE 320 - 480 -------------------------------*/
    .register-header {
        font-size: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        color: #4e5180;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
    }

    .conditions {
        color: #4e5180;
        font-family: "Poppins", sans-serif;
    }

    .register-button {
        margin-top: 1rem;
        margin-bottom: 3rem;
        font-family: "Poppins", sans-serif;
        border: none;
    }

    /*------------------USERPAGE 320-480 ---------------------------------*/
    .user-page {
        overflow-x: hidden;
    }

    .welcome-greeting {
        color: #35413e;
        margin: 0 1rem;
    }

    .video-link,
    .health-link,
    .account-link {
        color: #4e5180;
        padding-left: 0;
        font-family: "Poppins", sans-serif;
    }

    .video-link:hover span span,
    .health-link:hover span span,
    .account-link:hover span span {
        font-weight: bold;
        color: #35385d;
    }

    .health-link,
    .video-link {
        color: grey;
    }

    .video-link {
        color: #fff;
    }

    .tilaustiedot {
        color: #4e5180;
        margin: 0.6rem;
        font-family: "Poppins", sans-serif;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link>a {
        color: #fff;
        background: #4e5180 !important;
        text-align: center;
        font-family: "Poppins", sans-serif;
        margin: 0 0.79rem 0 0;
    }

    .nav-pills .nav-link {
        text-align: center;
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
    }

    .added-members {
        padding: 1rem 0 0 1rem;
        color: #4e5180;
    }

    .members-list {
        /* padding: 0 1rem 2rem 1rem; */
        padding-left: 1rem;
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
        /* display: inline-block; */
    }

    .group {
        padding-bottom: 1rem;
    }

    .membership {
        list-style-type: none;
    }

    .role {
        margin-bottom: 0;
    }

    .alert-success {
        background-color: var(--light-background);
        border: none;
        color: var(--dark-primary);
        font-family: "Poppins", sans-serif;
    }

    .what-is-this {
        color: #212529;
    }

    .checkbox-later {
        color: rgb(36, 35, 35);
    }

    .is-senior-button,
    .what-is-this,
    .for-example,
    .form-check-label {
        background-color: #fff !important;
        color: rgb(80, 78, 78);
        font-family: "Poppins", sans-serif;
    }

    .accordion-btn {
        border: solid 1px rgb(177, 173, 173);
        color: rgb(24, 23, 23);
        min-width: 100%;
        margin-bottom: 1rem;
    }

    .accordiong-btn:hover {
        text-decoration: none;
    }

    .nursering-home-input {
        margin-bottom: 1rem;
    }

    .invisible {
        visibility: hidden;
    }

    .send-member-email-col {
        display: flex;
        justify-content: center;
    }

    .send-member-email-button {
        margin-left: 0;
    }

    .member-button,
    .send-member-email-button {
        width: 100%;
    }

    .step-line {
        display: none;
    }

    .subscribe-fab-div {
        display: none;
    }
}

@media only screen and (min-width: 320px) and (max-device-width: 504px) {
    .contact-form {
        /*width: 80vw;*/
        /*position: relative;*/
        /*top: 10vh;*/
    }
}

@media only screen and (min-width: 504px) and (max-device-width: 619px) {
    .contact-form {
        /*width: 80vw;*/
        /*position: relative;*/
        /*top: 10vh;*/
    }
}

.privacy-policy-text {
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    /*line-height: 2;*/
    letter-spacing: 0.5;
    margin: 3rem auto 0.5rem auto;
    width: 80%;
    color: var(--dark-primary);
}

.sub-header-text {
    width: 100%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    padding: 1rem;
    font-size: 1rem;
    color: #4e5180;
    /*border-top: 1px solid #00000017;*/
    /*border-bottom: 1px solid #00000017;*/
}

.custom-nav {
    /*font-size: 1rem;*/
    padding: 0.15rem 1rem;
}

.privacy-policy-header {
    color: #4e5180;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    padding-top: 1.7rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.privacy-policy-header2 {
    color: #4e5180;
    font-weight: 600;
    font-size: 1.8rem;
    font-family: "Montserrat", sans-serif;
    padding-top: 1.7rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.linkki {
    margin: auto;
    font-family: "Poppins", sans-serif;
    color: #4e5180;
    font-weight: 600;
}

.linkki:hover {
    color: #35385d;
    text-decoration: none;
}

@media only screen and (min-width: 900px) {
    .privacy-policy-dates-container {
        position: relative;
        min-height: 64px;
    }

    .privacy-policy-dates-left {
        float: left;
    }

    .privacy-policy-dates-right {
        position: absolute;
        right: 0;
    }
}

.steps-notification {
    height: 184px;
    width: 184px;
    position: fixed;
    bottom: 24px;
    right: 24px;
    background: #4e5180;
    border-radius: 50%;
    padding: 8px;
    font-size: 1rem;
    color: white;
    text-align: center;
    z-index: 999;
}

.steps-notification div {
    margin-top: 24px;
}

.steps-notification a {
    color: white;
}

@media only screen and (max-device-width: 600px) {
    .steps-notification {
        height: auto;
        width: auto;
        position: relative;
        display: block;
        bottom: auto;
        left: auto;
        right: auto;
        border-radius: 0;
        padding: 2px;
        font-size: 1rem;
        text-align: center;
        margin-bottom: 48px;
        margin-top: 24px;
    }

    .steps-notification div {
        margin-top: 0;
    }

    .steps-notification br {
        content: " ";
    }

    .steps-notification br:after {
        content: " ";
    }

    .shoppingcart-container,
    .register-form-container {
        margin-bottom: 100px;
    }
}

.form-hint {
    font-size: small;
    color: rgba(0, 0, 0, 0.59);
}

.shipping-form label {
    width: 100%;
}

.play-btn {
    position: absolute;
    z-index: 666;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: 0;
}

.play-btn:hover {
    cursor: pointer;
}

.play-btn:focus {
    outline: 0;
}

.palvelut-table {
    color: var(--text-primary);
}

.palvelut-table>tbody>tr:nth-of-type(odd) {
    background: var(--light-background);
}

.palvelut-table th,
.palvelut-table td {
    border: 1px solid var(--gray-primary);
    vertical-align: top;
}

.nav-tabs {
    border: none;
}

.add-to-shoppingcart:hover {
    color: #35385d;
    text-decoration: none;
}

.order-conditions:hover {
    color: #35385d;
    text-decoration: none;
}

.conditions:hover {
    color: #35385d;
    font-weight: bold;
    text-decoration: none;
}

.forgot-password:hover {
    color: #35385d;
    font-weight: bold;
    text-decoration: none;
}

.muistan-salasanan {
    color: #4e5180;
    font-family: "Poppins", sans-serif;
}

.muistan-salasanan:hover {
    color: #35385d;
    font-weight: bold;
    text-decoration: none;
}

.mailto {
    color: #4e5180;
    font-family: "Poppins", sans-serif;
}

.mailto:hover {
    color: #35385d;
    font-weight: bold;
    text-decoration: none;
}

.aboutus-partners-col {
    text-align: center;
    padding: 32px;
}

.aboutus-partners {
    max-width: 100%;
    width: auto;
    max-height: 80px;
}

.aboutus-photo {
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.aboutus-photo-img-1 {
    height: 300px;
    width: 240px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aboutus-photo-img-2 {
    height: 300px;
    width: 240px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aboutus-photo-img-3 {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aboutus-photo-box {
    margin: 1rem;
    border: 2px solid #c0c0c0;
}

.aboutus-photo-caption {
    min-height: 100px;
    color: #4e5180;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 1rem;
    margin: 0 auto;
    max-width: 240px;
}

.termscondition input {
    width: auto;
    height: auto;
    display: inline-block;
}

.termsLabel {
    margin-left: 20px;
}

.autocomplete-options-container {
    display: flex;
    flex-direction: row;
}

.autocomplete-options-container>div {
    margin: 5px;
}

.autocomplete-options-container div:last-child {
    margin-left: auto;
}

.autocomplete-option-image {
    height: 50px;
    width: 50px;
}

.autocomplete-option-btn {
    width: 6rem;
    border-radius: 0.5rem;
    margin-left: auto;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.switch-slider {
    background-color: #23a317;
}

input:focus+.switch-slider {
    box-shadow: 0 0 1px #23a317;
}

input:checked+.switch-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.switch-slider.switch-round {
    border-radius: 34px;
    border: 2px solid black;
}

.switch-slider.switch-round:before {
    border-radius: 50%;
    border: 2px solid black;
}

/* Custom Alert Message */
.alert-message {
    width: 15%;
    margin: auto;
}

/* Space between Edit and Delete icons */
.action-items {
    margin-right: 1rem;
}

/* Style for module title */
.module-title {
    color: #4e5180;
    font-family: Poppins, sanf-serif;
}

/* Style for error message */
.error-message {
    width: 20%;
    margin: auto;
}

/* Style for Alert Message */
.message-text {
    text-align: center;
}

/* Style for "add new" buttons */

.headind-button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.add-button {
    background-color: #433289;
    border-color: #433289;
    font-weight: 700;
    margin: 15px 55px 0px 0px;
    float: right;
    font-size: 22px;
}

.add-button:hover {
    background-color: #332372;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

footer {
    flex-shrink: 0;
}

td {
    /*overflow-wrap: break-word;*/
    hyphens: manual;
}

.show-password {
    width: 100%;
    text-align: left;
    color: #4e5180;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
}

.show-password-btn {
    width: 46px;
    border-radius: 0 0.25rem 0.25rem 0;
}

.show-refresh-btn {
    width: 46px;
    border-radius: 0 0 0 0;
    border-right: none;
}

input[name="password"] {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: none;
}

.feature_choices {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    width: 100%;
    color: var(--text-primary);
}

.feature_choices-text {
    font-size: 1rem;
}

.feature_choices-form {}

.feature_choices-option {
    margin-left: 20px;
}

#controlled-tab-tab-features {
    display: flex;
    flex-grow: 1;
}

.consent_banner {
    color: #595764;
    font-size: 1.4rem;
    position: fixed;
    width: 100%;
    min-height: 64px;
    background-color: #ffffff;
    bottom: 0;
    max-width: 100vw;
    text-align: center;
}

.consent_button {
    font-size: 1.2rem;
    background-color: #e9ebea;
    border: none;
    border-radius: 20px;
    margin: 0;
    width: 80%;
    color: #5b5b81;
}

.consent_button.reverse {
    background-color: #5b5b81;
    color: #e9ebea;
}

.consent_button:hover,
.consent_button:active,
.consent_button:focus {
    color: #e9ebea;
    background-color: #5b5b81;
}

.consent_button.reverse:hover,
.consent_button.reverse:active,
.consent_button.reverse:focus {
    border: 1px solid #5b5b81;
    color: #5b5b81;
    background-color: #e9ebea;
}

.article_in_blog:hover {
    cursor: pointer;
}

.fa-exclamation-circle:before {
    margin: 0 -2px;
}

/* Pre Join Room Screen */
.pre-join-section {
    width: 100%;
    height: 100%;
}

.pre-join-section .lk-prejoin {
    width: 55%;
}

.pre-join-section .lk-prejoin .lk-video-container {
    border-radius: 10px;
}

.pre-join-section .lk-prejoin .lk-button-group-menu .lk-device-menu {
    top: 50px !important;
    background: #fff;
    left: -360.828px !important;
}

.pre-join-section .lk-button-group-container .lk-button-group {
    background: #f0f0f0;
    border-radius: 40px;
}

.pre-join-section .lk-prejoin .lk-username-container .lk-form-control {
    border-radius: 40px;
    height: 40px;
    padding: 0 15px;
    outline: none;
    border: 1px solid #000;
}

.pre-join-section .lk-prejoin .lk-username-container .lk-join-button {
    background: #1a73e8;
    color: #fff;
    border-radius: 40px;
}

/* editable css start */

.crowded-room {
    --lk-row-count: 2 !important;

    @media screen and (max-width: 767px) {
        --lk-row-count:1 !important;
    }
}

.two-crowded-room {
    @media screen and (max-width: 767px) {
        --lk-col-count: 1 !important;
        --lk-row-count: 2 !important;
    }
}

.three-crowded-room {
    @media screen and (max-width: 767px) {
        --lk-row-count: 3 !important;
    }
}

.five-crowded-room {
    @media screen and (max-width: 767px) {
        --lk-col-count: 2 !important;
        --lk-row-count: 3 !important;
    }
}

.six-crowded-room {
    @media screen and (max-width: 767px) {
        --lk-col-count: 2 !important;
        --lk-row-count: 3 !important;
    }
}

.lk-room-container {
    @media screen and (max-width: 1280px) and (orientation: landscape) {
        height: 100vh !important;
    }
}

.lk-room-container {
    @media screen and (max-width: 1280px) and (orientation: portrait) {
        height: 100vh !important;
    }
}

.lk-grid-layout:has(.lk-participant-tile:first-of-type) .lk-participant-tile  {
    @media screen and (max-width: 576px) {
        height: 43vh !important;
        
    }
}


/* .lk-room-container .third-tile{
    @media screen and (max-width: 767px){
        height: auto !important;
        max-height: 50% !important 
    }
} */

/* .lk-room-container .fifth-tile{
    @media screen and (max-width: 767px){
        height: auto !important;
        max-height: 50% !important;
    }
}

.lk-room-container .seventh-tile{
    @media screen and (max-width: 767px){
        height: auto !important;
        max-height: 50% !important;
    }
} */

header.prejoin-room {
    /* @media screen and (max-width: 1280px) { */
    display: none;
    /* } */
}

.consent_banner.prejoin-room {
    /* @media screen and (max-width: 1280px) { */
    display: none;
    /* } */
}

footer.prejoin-room {
    /* @media screen and (max-width: 1280px) { */
    display: none;
    /* } */
}

.weglot-container.prejoin-room {
    display: none !important;
}

.lk-control-bar {
    position: fixed;
    /* bottom: 30px; */
    left: 50%;
    transform: translateX(-50%);
    /* @media screen and (max-width: 1280px) { */
    bottom: 0 !important;
    /* } */
}

/* editable css end */

@media screen and (max-width: 1440px) {
    .pre-join-section .lk-prejoin {
        width: 65%;
    }
}

@media screen and (max-width: 1199px) {
    .pre-join-section .lk-prejoin {
        width: 70%;
    }
}

@media screen and (max-width: 991px) {
    .pre-join-section .lk-prejoin {
        width: 80%;
    }
}

@media screen and (max-width: 767px) {
    .pre-join-section .lk-prejoin {
        width: 95%;
    }

    .lk-button-group-container {
        flex-direction: column;
    }

    .lk-button-group-container .lk-button-group {
        width: 100% !important;
    }
}

.subscribe-header-blue {
    margin: 0;
    padding: 32px;
    padding-left: calc(10% + 1.5rem);
    background-color: #e5e8f1;
    text-transform: uppercase;
    color: #5b5b81;
    font-weight: lighter;
}

.subscribe-header-blue-icon {
    height: 64px;
    width: 64px;
    float: left;
    margin-left: -10%;
    margin-top: -12px;
    background-size: contain;
}

.for-companies-ul {
    list-style: none;
    /* Remove default bullets */
    list-style-position: inside;
}

.for-companies-li::before {
    content: "\2022";
    color: #4e5180;
    font-weight: bold;
    font-size: 3rem;
    display: inline-block;
    width: 0.6em;
    margin-left: -0.6em;
    line-height: 1rem;
    vertical-align: middle;
}

/* GLOBAL STYLES TO OVERRIDE WRONG CSS */
.global-card-image {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

/*  */
/* GLOBAl COLOR STYLES */
.global-white {
    color: var(--white);
}

.global-primary {
    color: var(--text-primary);
}

/* GLOBAL TEXT STYLES */
.global-text {
    color: var(--text-primary);
    font-family: "Poppins", sans-serif;
}

.global-text-dark {
    color: var(--dark-primary);
}

.global-text-normal {
    font-size: 1.5rem;
    font-weight: 500;
}

.global-text-small {
    font-size: 1.2rem;
    font-weight: 500;
}

.global-section-title {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 2rem;
}

.global-text-strong {
    font-weight: 700;
}

.global-link {
    color: var(--text-primary);
    text-decoration: none;
    transition: all 0.5s;
    font-weight: 500;
}

.global-link:hover {
    text-decoration: none;
    color: var(--dark-primary);
}

/* video */
.global-youtube-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.global-video-overlay {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: opacity 0.5s ease-in-out;
}

.global-hover-zoom {
    transition: all 0.2s ease-in-out;
}

.global-hover-zoom:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.1);
}

.global-credit-image-container {
    position: relative;
}

.global-credit-text {
    position: absolute;
    bottom: 0.8rem;
    left: 1rem;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--dark-primary);
    font-weight: 600;
    letter-spacing: 0.2rem;
}

.global-credit-text-right {
    position: absolute;
    bottom: 0.8rem;
    right: 1rem;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--dark-primary);
    font-weight: 600;
    letter-spacing: 0.2rem;
}

.global-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.disabled {
    cursor: not-allowed !important;
}

.container_faq {
    width: 70%;
    margin: 0 auto;
    margin-top: 32px;
    padding-bottom: 18px;
}

.container_faq .accordion-section details {
    font-size: 1.5rem;
    color: #4e5180;
    font-family: "Poppins"
}

.container_faq .accordion-section summary {
    font-size: 1.8rem;
}

.container_faq .accordion-section ol {
    font-size: 1.5rem;
}

.container_faq .accordion-section .btn-header-link {
    color: #4e5180;
    padding: 12px 32px;
    font-size: 24px;
    font-weight: 700;
    background: #e8e9f6;
    margin-bottom: 16px;
    border: none;
    border-radius: 50px;
}

@media screen and (max-width: 768px) {
    .global-text-normal {
        font-size: 1.2rem;
    }

    .global-section-title {
        font-size: 1.5rem;
    }

    .global-footer-center {
        justify-content: center !important;
    }
}

@media screen and (max-width: 480px) {
    .global-credit-text {
        bottom: 0.5rem;
        left: 0.5rem;
        font-size: 10px;
        text-transform: uppercase;
        color: var(--dark-primary);
        font-weight: 600;
        letter-spacing: 0.1rem;
    }

    .global-credit-text-right {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        font-size: 10px;
        text-transform: uppercase;
        color: var(--dark-primary);
        font-weight: 600;
        letter-spacing: 0.1rem;
    }
}